import { TranslationWidth } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker-range-mobile',
  templateUrl: './date-picker-range-mobile.component.html',
  styleUrl: './date-picker-range-mobile.component.scss'
})
export class DatePickerRangeMobileComponent implements OnInit, AfterViewInit {
  _dateRange: Date[] = new Array<Date>();
  translationWidth: TranslationWidth = TranslationWidth.Abbreviated;
  @Input()
  set minDate(value: Date) {
    if (value)
      this._minDate = {
        day: value.getDate(),
        month: value.getMonth() + 1,
        year: value.getFullYear()
      };
  }
  @Input()
  set departDate(value: Date) {
    this._departDate = value;
    this.startDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
    if (value)
      this.fromDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
  }

  @Input()
  disableRange = false;

  @Input()
  set arrivalDate(value: Date) {
    this._arrivalDate = value;
    if (value)
      this.toDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
  }

  @Input()
  public inline: boolean = true;
  @Input()
  public disabled: boolean = true;

  public minDefaultDate: Date = new Date();
  public departDateHover: Date = new Date();
  public arrivalDateHover: Date = new Date();

  public firstDate: boolean = false
  public secondDate: boolean = false
  monthsToDisplay: number = 12;
  modalTitle: string = ''
  serializedDates: number[] = [];

  hoveredDate: NgbDate = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  fromDate: NgbDate = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  toDate: NgbDate | null = null;
  _minDate: NgbDateStruct = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  _maxDate: NgbDateStruct = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  startDate: NgbDate = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
  isClickOnFirst: boolean;
  _departDate: Date = new Date();
  _arrivalDate: Date = new Date();

  @ViewChild("mobilePicker")
  mobilePicker!: ElementRef;

  selectingDeparture = true;
  selectingArrival = false;

  constructor(
    calendar: NgbCalendar,
    @Inject(MAT_BOTTOM_SHEET_DATA) private inputs: any,
    private bottomSheetRef: MatBottomSheetRef<DatePickerRangeMobileComponent>
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
    this.departDate = inputs.departDate;
    this.arrivalDate = inputs.arrivalDate;
    this.isClickOnFirst = inputs.clickOnFirst;
    this.disableRange = inputs.disableRange;
    if (inputs.monthsToDisplay) this.monthsToDisplay = inputs.monthsToDisplay;
    if (inputs.modalTitle) this.modalTitle = inputs.modalTitle;
    if (inputs.availableDates) {
      this.serializedDates = inputs.availableDates.map((d: string | number | Date) => +d);
    }
  }

  ngOnInit() {
    this._minDate = this.inputs.minDate;
    this._maxDate = this.inputs.maxDate;
  }

  ngAfterViewInit() {
    const monthsRow = this.mobilePicker.nativeElement.querySelectorAll(".ngb-dp-months");
    monthsRow[0].classList.add("row");
    const monthsCols = monthsRow[0].querySelectorAll(".ngb-dp-month");
    monthsCols.forEach((element: HTMLElement) => {
      element.classList.add("col-12");
    });
  }

  disMissBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  isHovered(date: NgbDateStruct): boolean {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      this.compareDates(date, this.fromDate) > 0 &&
      this.compareDates(date, this.hoveredDate) < 0
    );
  }

  isInside(date: NgbDateStruct): boolean {
    return (
      this.fromDate != null &&
      this.toDate != null &&
      this.compareDates(date, this.fromDate) > 0 &&
      this.compareDates(date, this.toDate) < 0
    );
  }

  isRange(date: NgbDateStruct): boolean {
    return (
      this.compareDates(date, this.fromDate) === 0 ||
      (this.toDate && this.compareDates(date, this.toDate) === 0) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  private compareDates(date1: NgbDateStruct, date2: NgbDateStruct): number {
    if (!date1 || !date2) return 0;
    const d1 = new Date(date1.year, date1.month - 1, date1.day);
    const d2 = new Date(date2.year, date2.month - 1, date2.day);
    return d1.getTime() - d2.getTime();
  }

  isSelected(date: NgbDate) {
    return this.fromDate.equals(date);
  }

  getDepartureDate(): Date {
    return this._departDate;
  }

  getArrivalDate(): Date {
    return this._arrivalDate;
  }

  selectDepartureDate() {
    this.selectingArrival = false;
    this.selectingDeparture = true;
    this.isClickOnFirst = true;
  }

  selectArrivalDate() {
    this.selectingDeparture = false;
    this.selectingArrival = true;
    this.isClickOnFirst = false;
  }

  onDateSelection(date: NgbDate) {
    const _date = new Date(date.year, date.month - 1, date.day);
    if (!this.fromDate && !this.toDate && this.isClickOnFirst) {
      this.fromDate = date;
      this._departDate = _date;
      this.startDate = date;
      this.selectingDeparture = false;
      this.selectingArrival = true;
    } else if (
      this.fromDate &&
      (!this.toDate || !this.isClickOnFirst) &&
      (date.after(this.fromDate) || date.equals(this.fromDate))
    ) {
      this.toDate = date;
      this._arrivalDate = _date;
      this.selectingArrival = false;
      this.selectingDeparture = true;
      this.isClickOnFirst = true;
    } else {
      this.toDate = null;
      this.fromDate = date;
      this._departDate = _date;
      this.startDate = date;
      this.selectingDeparture = false;
      this.selectingArrival = true;
    }
  }

  onSingleDateSelection(date: NgbDateStruct) {
    const selectedDate = new Date(date.year, date.month - 1, date.day);
  
    this.toDate = null;
    this.fromDate = new NgbDate(date.year, date.month, date.day);
    this.startDate = new NgbDate(date.year, date.month, date.day);
  
    this.bottomSheetRef.dismiss({
      datepickerMode: "single",
      date: date
    });
  }

  applyDates() {
    this.bottomSheetRef.dismiss({
      datepickerMode: "range",
      startDate: this.fromDate,
      endDate: this.toDate
    });
  }

  isDisabled = (date: NgbDateStruct, current?: { year: number; month: number }): boolean => {
    if (!current) {
      return false;
    }
  
    if (this.serializedDates && this.serializedDates.length) {
      const currDate = new Date(date.year, date.month - 1, date.day);
      return this.serializedDates.indexOf(+currDate) === -1;
    }
    return false;
  };
  
}
