import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { debounceTime, distinctUntilChanged, filter, Observable, Subscription, switchMap } from 'rxjs';
import { AirportService } from 'src/app/services/airport.service';
import { AirportData, AirportSearch } from './models/airport-search.model';
import { EnvironmentService } from 'src/app/services/environment.service';
import { AirportSearchMobileComponent } from './airport-search-mobile/airport-search-mobile.component';

@Component({
  selector: 'app-airport-search',
  templateUrl: './airport-search.component.html',
  styleUrl: './airport-search.component.scss'
})
export class AirportSearchComponent implements OnInit, OnDestroy {
  airportsSub: Subscription = new Subscription();
  @Input() formInput: FormControl = new FormControl();
  @Output() formInputChange = new EventEmitter<FormControl>();
  groupedAirports: AirportSearch[] = []

  @Input() placeHolder: string = "origen";

  isMobile = false;

  @ViewChild('inputElement') input!: ElementRef;

  setFocus() {
    if (this.input && this.input.nativeElement) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 0);
    }
  }

  @Output() setFocusChange = new EventEmitter<boolean>();

  constructor(
    private cityAutocomplete: AirportService,
    private env: EnvironmentService,
    private bottomSheet: MatBottomSheet) {

  }

  ngOnInit(): void {
    this.airportsSub = this.createOptionsObservable(this.formInput).subscribe(items => {
      this.groupedAirports = items;
    });

    this.isMobile = this.env.isMobile;
  }

  spinning() {
    if (typeof this.formInput.value === "string" && this.formInput.value.length && this.formInput.value.length > 2) return true;
    else return false;
  }

  getDisplay(elem?: AirportData): string {
    if (elem) {
      if (elem.selectedDisplayName)
        return elem.selectedDisplayName;
      if (elem.name)
        return elem.name;
    }

    return '';
  }

  getCode(elem?: AirportData) {
    return elem ? elem.code : undefined;
  }

  getCityName(elem?: AirportData) {
    return elem ? elem.name : undefined;
  }

  selectFirstOption() {
    if (
      typeof this.formInput.value === "string" &&
      this.groupedAirports &&
      this.groupedAirports[0] &&
      this.groupedAirports[0].cityAirport
    )
      this.formInput.setValue(this.groupedAirports[0].cityAirport);
  }

  createOptionsObservable(control: FormControl): Observable<AirportSearch[]> {
    return control.valueChanges.pipe(
      filter(text => {
        return typeof text === "string" || text instanceof String;
      }),
      filter(text => text.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(token => {
        return this.cityAutocomplete.getCityAirportsOptions(token);
      })
    );
  }

  onOptionSelected(){
    this.formInputChange.emit(this.formInput);
  }

  ngOnDestroy(): void {
    if (this.airportsSub) this.airportsSub.unsubscribe();
  }

  onFocus(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    if (target) {
      target.select();
    }
  }

  showMobileForm(event: MouseEvent) {
    if (this.isMobile) {
      event.preventDefault();
      const airportSearchBS = this.bottomSheet.open(AirportSearchMobileComponent, {
        data: {
          title: this.placeHolder
        },
        autoFocus: false
      });
      airportSearchBS.afterDismissed().subscribe(data => {        
        if (data) {
          this.formInput.setValue(data.value);
          this.formInputChange.emit(this.formInput);
        }
      });
      return false;
    }

    return false
  }
  
}
