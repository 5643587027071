import { Component } from '@angular/core';

@Component({
  selector: 'app-rooms-counter-panel',
  templateUrl: './rooms-counter-panel.component.html',
  styleUrls: ['./rooms-counter-panel.component.scss']
})
export class RoomsCounterPanelComponent {

}
