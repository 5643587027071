import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CabinType } from 'src/app/models/flights/flight-utilities';
import { PassengerSearch } from 'src/app/models/passenger-search';

@Component({
  selector: 'app-passengers-counter-panel-mobile',
  templateUrl: './passengers-counter-panel-mobile.component.html',
  styleUrl: './passengers-counter-panel-mobile.component.scss'
})
export class PassengersCounterPanelMobileComponent implements OnInit {
  passengers: PassengerSearch = { adults: 1, childrens: 0, infants: 0, cabinType: CabinType.All };
  
  changingPassengers: PassengerSearch = { adults: 1, childrens: 0, infants: 0, cabinType: CabinType.All };

  constructor(
    private bottomSheetRef: MatBottomSheetRef<PassengersCounterPanelMobileComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.passengers = this.data.passengers
    this.setInitialValues();
  }

  isButtonDisabled(passengerType: string, buttonType: string): boolean {
    if (buttonType === 'minus') {
      if (passengerType === 'adults') {
        return this.changingPassengers.adults <= 0
      } else if (passengerType === 'children') {
        return this.changingPassengers.childrens  <= 0
      } else {
        return this.changingPassengers.infants <= 0
      }
    }else {
      if (passengerType === 'adults') {
        return this.changingPassengers.adults >= 9
      } else if (passengerType === 'children') {
        return this.changingPassengers.childrens  >= 6
      } else {
        return this.changingPassengers.infants >= 6
      }
    }
  }

  applyPassengers() {
    this.passengers = { ...this.changingPassengers };
    this.bottomSheetRef.dismiss(this.passengers);
  }

  cancelPassengers() {
    this.bottomSheetRef.dismiss(null);
  }

  setInitialValues() {
    this.changingPassengers = { ...this.passengers };
  }

  add(passengerType: string){
    if (passengerType === 'adult') {
      this.changingPassengers.adults++
    } else if (passengerType === 'children') {
      this.changingPassengers.childrens++
    } else if (passengerType === 'infants') {
      this.changingPassengers.infants++
    }
  }

  subtract(passengerType: string) {
    if (passengerType === 'adult') {
      this.changingPassengers.adults--
    } else if (passengerType === 'children') {
      this.changingPassengers.childrens--
    } else if (passengerType === 'infants') {
      this.changingPassengers.infants--
    }
  }

  isApplyButtonDisabled() {
    return this.changingPassengers.adults === 0;
  }

  getCabinTypeName(cabinType: CabinType) {
    switch (cabinType) {
      case CabinType.All: return "Todas las cabinas";
      case CabinType.Tourist: return "Turista";
      case CabinType.Business: return "Ejecutiva";
      case CabinType.FirstClass: return "Primera Clase";
    }
  }

  getCabinTypes(): CabinType[] {
    return Object.values(CabinType).filter(value => typeof value === 'number') as CabinType[];
  }
}
