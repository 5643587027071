<div class="t-newsletter d-sm-none">
  <ng-container [formGroup]="form">
    <div class="text-white px-3 py-4" *ngIf="!subscribed">
      <h2 class="d-inline-flex mb-4">Newsletter</h2>
      <mat-form-field appearance="outline" class="t-mat-form-field mb-2">
        <input type="email" matInput placeholder="Ingresá tu email" class="text-white" formControlName="email">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          <i class="fa-duotone fa-circle-info text-danger me-1"></i> Por favor, ingresa un email válido.
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          <i class="fa-duotone fa-circle-info text-danger me-1"></i> El email es requerido
        </mat-error>
      </mat-form-field>
      <button (click)="submit(form.value)" mat-flat-button color="accent" class="w-100 fw-semibold py-4 mb-3">Suscribirme</button>
      <p>
        <small>
          Al suscribirte acept&#xE1;s nuestros
          <a href="https://www.toctocviajes.com/terms" target="_blank" class="text-white text-decoration-underline">
            T&#xE9;rminos y condiciones
          </a>.
        </small>
      </p>
    </div>
    <div class="text-white text-center" *ngIf="subscribed">
      <div class="text-center text-white px-3 py-4">
        <i class="fa-regular fa-circle-check fa-2xl mb-4"></i>
        <h2 class="fs-5 mb-2">¡Felicitaciones!</h2>
        <p class="fs-6 lh-sm text-white-50"><small> Vas a estar recibiendo en tu mail las mejores promos para viajar, tips toc y mucho más.</small>
        </p>
      </div>
    </div>
  </ng-container>
</div>
