import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { CabinType } from 'src/app/models/flights/flight-utilities';
import { PassengerSearch } from "src/app/models/passenger-search";
import { EnvironmentService } from 'src/app/services/environment.service';
import { PassengersCounterPanelMobileComponent } from './passengers-counter-panel-mobile/passengers-counter-panel-mobile.component';

@Component({
  selector: 'app-passengers-counter-panel',
  templateUrl: './passengers-counter-panel.component.html',
  styleUrls: ['./passengers-counter-panel.component.scss']
})
export class PassengersCounterPanelComponent implements OnInit {  
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @Input() passengers: PassengerSearch = { adults: 1, childrens: 0, infants: 0, cabinType: CabinType.All }
  @Output() passengersChange = new EventEmitter<PassengerSearch>()
  cabinTypes = Object.keys(CabinType)

  changingPassengers: PassengerSearch = { adults: 1, childrens: 0, infants: 0, cabinType: CabinType.All }

  isMobile: boolean = false

  constructor(private env: EnvironmentService,
    private bottomSheet: MatBottomSheet) { }

  ngOnInit(): void {
    this.setInitialValues()

    this.isMobile = this.env.isMobile
  }

  setFocus(){
    if (!this.isMobile) {
      this.menuTrigger.openMenu()
    } else {
      this.showMobileForm(new MouseEvent('click'))
    }
  }

  isButtonDisabled(passengerType: string, buttonType: string): boolean {
    if (buttonType === 'minus') {
      if (passengerType === 'adults') {
        return this.changingPassengers.adults <= 0
      } else if (passengerType === 'children') {
        return this.changingPassengers.childrens  <= 0
      } else {
        return this.changingPassengers.infants <= 0
      }
    }else {
      if (passengerType === 'adults') {
        return this.changingPassengers.adults >= 9
      } else if (passengerType === 'children') {
        return this.changingPassengers.childrens  >= 6
      } else {
        return this.changingPassengers.infants >= 6
      }
    }
  }

  applyPassengers() {
    this.passengers = { 
      adults: this.changingPassengers.adults, 
      childrens: this.changingPassengers.childrens, 
      infants: this.changingPassengers.infants, 
      cabinType: this.changingPassengers.cabinType
    }
    this.passengersChange.emit(this.passengers)
    this.menuTrigger.closeMenu()
  }

  cancelPassengers() {
    this.setInitialValues()
    this.menuTrigger.closeMenu()
  }

  setInitialValues() {
    this.changingPassengers = { 
      adults: this.passengers.adults, 
      childrens: this.passengers.childrens, 
      infants: this.passengers.infants, 
      cabinType: this.passengers.cabinType
    }
  }

  add(passengerType: string){
    if (passengerType === 'adult') {
      this.changingPassengers.adults++
    } else if (passengerType === 'children') {
      this.changingPassengers.childrens++
    } else if (passengerType === 'infants') {
      this.changingPassengers.infants++
    }
  }

  subtract(passengerType: string) {
    if (passengerType === 'adult') {
      this.changingPassengers.adults--
    } else if (passengerType === 'children') {
      this.changingPassengers.childrens--
    } else if (passengerType === 'infants') {
      this.changingPassengers.infants--
    }
  }

  isApplyButtonDisabled() {
    return this.changingPassengers.adults === 0
  }

  getCabinTypeName(cabinType: CabinType) {
    switch (cabinType) {
      case CabinType.All:
        return "Todas las cabinas"
      case CabinType.Tourist:
        return "Turista"
      case CabinType.Business:
        return "Ejecutiva"
      case CabinType.FirstClass:
        return "Primera Clase"
    }
  }

  getCabinTypeNameByIndex(index: number) {
    return index.toString()
  }

  getalgo(cabin: string) {
    return cabin
  }

  getPassangerCount() {
    return this.passengers.adults + this.passengers.childrens + this.passengers.infants
  }

  getCabinType() {
    return this.getCabinTypeName(this.passengers.cabinType)
  }

  getCabinTypes(): CabinType[] {
    return Object.values(CabinType).filter(value => typeof value === 'number') as CabinType[];
  }

  showMobileForm(event: MouseEvent) {
    if (this.isMobile) {
      event.preventDefault();
      
      const passengerSearchBS = this.bottomSheet.open(PassengersCounterPanelMobileComponent, {
        data: {
          passengers: this.changingPassengers
        },
        autoFocus: false
      });
      passengerSearchBS.afterDismissed().subscribe(data => {   
        if (data) {
          this.changingPassengers = data

          this.applyPassengers()
        }
      });
      return false;
    }

    return false
  }
}
