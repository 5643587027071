import { Component } from '@angular/core';
import { OfferService } from './services/offer.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Product } from './models/product.model';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})

export class OfferComponent {

  mainCampaign: any;
  offer$: Observable<Product[]> | undefined;
  index: any;
  offer : any;
  contentLoaded = false;

  constructor(public offerService: OfferService, private route: ActivatedRoute) {
    this.offer$ = this.route.queryParams.pipe(switchMap(params => {
      const filters = {
        region: params['region'] || "",
        product: params['product'] || ""
      };
      return this.offerService.getProducts(filters);
    }));
  }

  ngOnInit(): void {

    this.offerService.ReadMainCampaign().subscribe((response) => {
      this.mainCampaign = response;
    });

    setTimeout(() => {
      this.contentLoaded = true;
    }, 1500);
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}

