import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FlightSearchLegVM } from '../../flight-search-vm';
import { SearchFocusService } from 'src/app/services/search-focus.service';
import { AirportSearchComponent } from 'src/app/components/airport-search/airport-search.component';

@Component({
  selector: 'app-flights-search-leg',
  templateUrl: './flights-search-leg.component.html',
  styleUrl: './flights-search-leg.component.scss'
})
export class FlightsSearchLegComponent implements OnInit {
  @Input() leg: FlightSearchLegVM = new FlightSearchLegVM();
  @Output() legChange = new EventEmitter<FlightSearchLegVM>();
  @Input() displayMode: "inline" | "block" = "inline";
  setDestinationFocus = false;

  @ViewChild('destinationSearch') destinationAirport!: AirportSearchComponent;

  constructor(private searchFocus: SearchFocusService){

  }

  public getLegChange(leg: FlightSearchLegVM) {
    this.legChange.emit(leg);
  }

  public swapDestinations() {
    const temp = this.leg.origin.value;
    this.leg.origin.setValue(this.leg.destination.value);
    this.leg.destination.setValue(temp);
  }

  ngOnInit(): void {
  }

  onOriginChange() {
    this.destinationAirport.setFocus()

  }

  onDestinationChange() {
    this.searchFocus.completeDestination();
  }
}
