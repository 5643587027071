<!-- Login -->
<div class="t-login d-flex vh-100 overflow-hidden">

  <section class="t-sidebar-img col d-none d-lg-block p-5">
    <h1 class="h1 fw-bold text-white text-center">Conectamos amigos <br> con experiencias únicas</h1>
  </section>

  <section class="t-content-login bg-white col d-grid align-items-baseline overflow-auto">
    <nav class="d-flex justify-content-end w-100 border-bottom p-3">
      <a href="" class="text-dark"><i class="fa-regular fa-xmark fs-2"></i></a>
    </nav>
    <main class="d-flex justify-content-center align-items-center">
      <div class="t-content-login text-center px-3 py-5">
        <!-- Sign in -->
        <div class="d-none">
          <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto">

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Bienvenido de nuevo</h2>
            <p class="text-secondary m-0">Ingresá tus datos y accedé a tu cuenta.</p>
          </div>

          <form action="" class="text-start">
            <div class="row mb-3">
              <label class="fw-semibold mb-1">Email</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="email" matInput placeholder="Ingresa tu email" />
              </mat-form-field>
            </div>

            <div class="row">
              <label class="fw-semibold mb-1">Contraseña</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="password" matInput placeholder="Ingresa tu contraseña" [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <i class="fa-regular {{hide ? 'fa-eye-slash' : 'fa-eye'}} fa-2xs"></i>
                </button>
              </mat-form-field>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3">
              <mat-checkbox color="primary" checked class="ms-n2">Recordarme</mat-checkbox>
              <a href="" class="text-primary" aria-label="Recuperar contraseña">Recuperar contraseña</a>
            </div>

            <button type="button" mat-flat-button color="primary" aria-label="Ingresar a la cuenta"
              class="w-100 text-center mb-2 p-4">
              Ingresar
            </button>
            <button type="button" mat-stroked-button aria-label="Ingresar con Facebook" class="w-100 text-center p-4">
              <div class="d-flex align-items-center">
                <i class="fa-brands fa-facebook text-primary fs-5 me-2"></i>Ingresar con Facebook
              </div>
            </button>
          </form>

          <p class="mt-4 text-secondary">¿No tenes cuenta? <a href="" class="text-primary"
              aria-label="Crear cuenta">Crear una</a></p>
        </div>
        <!-- Recover password -->
        <div class="d-none">
          <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto">

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Recuperar contraseña</h2>
            <p class="text-secondary m-0">Ingresá el correo electrónico asociado con su cuenta y te enviaremos las
              instrucciones para restablecer su contraseña.</p>
          </div>

          <form action="" class="text-start">
            <div class="row mb-3">
              <label class="fw-semibold mb-3">Email</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="email" matInput placeholder="Ingresa el email de tu cuenta" />
              </mat-form-field>
            </div>

            <button type="button" mat-flat-button color="primary" aria-label="Enviar instrucciones"
              class="w-100 text-center mb-2 p-4">
              Enviar instrucciones
            </button>
            <button type="button" mat-stroked-button color="primary" aria-label="Volver" class="w-100 text-center p-4">
              <div class="d-flex align-items-center">
                Volver
              </div>
            </button>
          </form>
        </div>
        <!-- Check mail message -->
        <div class="d-none">
          <div class="row justify-content-center">
            <div
              class="bg-light rounded-circle w-auto p-5 position-relative d-flex justify-content-center align-items-center">
              <i class="fa-light fa-envelope fa-2xl position-absolute"></i>
            </div>
          </div>

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Revisá tu email</h2>
            <p class="text-secondary mb-2">Te enviamos las instrucciones de recuperación de contraseña a tu correo
              electrónico.</p>
            <p class="text-secondary mb-0">Si no recibiste el email, revisá tu casilla de spam o <a href=""
                aria-label="back">intentá con otro correo electrónico</a>.</p>
          </div>

          <button type="button" mat-flat-button color="primary" aria-label="Entendido"
            class="w-100 text-center mb-2 p-4">
            Entendido
          </button>
        </div>
        <!-- Sign Up - step 1 -->
        <div class="">
          <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto">

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Crear cuenta</h2>
            <p class="text-secondary m-0">Selecciona una de las opciones para continuar.</p>
          </div>

          <h4 class="mb-3 text-secondary">Continuar con</h4>
          <button type="button" mat-stroked-button aria-label="Ingresar con correo electrónico"
            class="w-100 p-4 justify-content-start mb-2">
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-envelope text-primary fs-5 me-2"></i>Correo electrónico
            </div>
          </button>
          <button type="button" mat-stroked-button aria-label="Ingresar con Facebook"
            class="w-100 p-4 justify-content-start">
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-facebook text-primary fs-5 me-2"></i>Ingresar con Facebook
            </div>
          </button>
        </div>
        <!-- Sign Up - step 2-->
        <div class="d-none">
          <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" width="120" alt="Logo TocTocViajes" class="d-flex mx-auto">

          <div class="row mt-4 mb-5">
            <h2 class="h2 mb-2">Crear cuenta</h2>
            <p class="text-secondary m-0">Ingresá tus datos para crear tu cuenta.</p>
          </div>

          <form action="" class="text-start">
            <div class="row mb-3">
              <label class="fw-semibold mb-1">Nombre y Apellido</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="text" matInput placeholder="Ej. Juan Perez" />
              </mat-form-field>
            </div>
            <div class="row mb-3">
              <label class="fw-semibold mb-1">Email</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="email" matInput placeholder="Ej. juan.carlos@correo.com" />
              </mat-form-field>
            </div>

            <div class="row mb-3">
              <label class="fw-semibold mb-1">Contraseña</label>
              <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages">
                <input type="password" matInput placeholder="Ingresa tu contraseña" [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <i class="fa-regular {{hide ? 'fa-eye-slash' : 'fa-eye'}} fa-2xs"></i>
                </button>
              </mat-form-field>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <h6 class="mb-3"><small>La contraseña debe tener:</small></h6>
              </div>
              <div class="col">
                <ul>
                  <li class="text-secondary mb-1">
                    <i class="fa-solid fa-circle-check fa-lg me-2 text-success"></i><small>Minúsculas</small>
                  </li>
                  <li class="text-secondary mb-1">
                    <i class="fa-solid fa-circle-check fa-lg me-2 opacity-25"></i><small>Mayúsculas</small>
                  </li>
                </ul>
              </div>
              <div class="col-auto">
                <ul>
                  <li class="text-secondary mb-1">
                    <i class="fa-solid fa-circle-check fa-lg me-2 opacity-25"></i><small>Números</small>
                  </li>
                  <li class="text-secondary mb-1">
                    <i class="fa-solid fa-circle-check fa-lg me-2 opacity-25"></i><small>Entre 8 y 16 characters</small>
                  </li>
                </ul>
              </div>
            </div>

            <button type="button" mat-flat-button color="primary" aria-label="Crear cuenta"
              class="w-100 text-center mb-2 p-4">
              Crear cuenta
            </button>
          </form>

          <p class="mt-4 text-secondary">
            ¿Ya tenés cuenta? <a href="" class="text-primary" aria-label="Ingresar">Iniciar sesión</a>
          </p>

          <hr>

          <p class="text-secondary">
            <small>
              El uso de tu cuenta quedará sujeto a estos <a href="" class="text-dark text-decoration-underline"
              aria-label="Ver Términos de uso">Términos de uso</a>. Tus datos personales se tratarán según nuestra <br
              class="d-none d-sm-block">
              <a href="" class="text-dark text-decoration-underline" aria-label="Ver Política de privacidad">Política de
              privacidad</a>.
            </small>
          </p>
        </div>
      </div>
    </main>
  </section>
</div>
