import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NewsletterService } from './../../pages/promotions/shared/services/newsletter/newsletter.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-desktop-newsletter',
  templateUrl: './desktop-newsletter.component.html',
  styleUrls: ['./desktop-newsletter.component.scss']
})

export class DesktopNewsletterComponent {
  @ViewChild('menuTrigger')
  menuTrigger!: MatMenuTrigger;
  isMenuOpened: boolean = false;
  form!: FormGroup;
  subscribed: boolean | undefined;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(public fb: FormBuilder, private newsletter: NewsletterService) {
    this.subscribed = false;
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  closeMenu(): void {
    this.menuTrigger.closeMenu();
  }

  submit(form: any) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.newsletter.PostSubscription(this.form.value).subscribe((response) => {
      this.subscribed = true;
      }
    );
  }
}
