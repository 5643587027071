<div class="container-lg pe-0">
  <div class="ttv-header-page position-relative w-100 pe-5">
    <h1 class="display-6 fw-semibold">Conocé las formas<br class="d-sm-none"> de pago</h1>
    <p class="fs-4 text-secondary">¡Pagá tu viaje como quieras!</p>
    <img class="ttv-img-smile" src="https://tocuyprodmedia.blob.core.windows.net/img/smile-hero-page--yellow.svg" alt="Yellow Smile">
  </div>
</div>

<div class="container-lg pb-5 mb-5">
  <div class="border border-dark border-opacity-10 bg-white rounded-3 p-4 py-5">
    <div class="row gx-5">
      <div class="col-12 px-sm-5">
        <h4 class="mb-5">Con Transferencia bancaria a la cuenta que desees:</h4>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 gx-5 gy-5">
      <div class="col d-flex flex-column gap-3 px-sm-5">
        <div class="ttv-logo-container mb-1">
          <img
            src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-itau.svg"
            alt="Logo Itau"
            width="40"
            height="40"
          />
        </div>
        <h5 class="fw-semibold m-0">Sólo desde cuentas ITAÚ</h5>
        <ul class="text-secondary">
          <li>N° de cuenta: 8330543</li>
          <li>Caja de Ahorros USD</li>
          <li>Nombre: Diegal S.A</li>
        </ul>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <div class="ttv-logo-container mb-1">
          <img
            src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-scotiabank.svg"
            alt="Logo Scotiabank"
            width="120"
            height="18"
          />
        </div>
        <h5 class="fw-semibold m-0">Solo desde cuentas Scotiabank</h5>
        <ul class="text-secondary">
          <li>N° de cuenta: 1863607701</li>
          <li>Cuenta Corriente USD</li>
          <li>Nombre: Diegal S.A</li>
        </ul>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <div class="ttv-logo-container mb-1">
          <img
            src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-bbva.svg"
            alt="Logo BBVA"
            width="80"
            height="20"
          />
        </div>
        <h5 class="fw-semibold m-0">Solo desde cuentas BBVA</h5>
        <ul class="text-secondary">
          <li>N° de cuenta: 21671737</li>
          <li>Cuenta Corriente USD</li>
          <li>Nombre: Diegal S.A</li>
        </ul>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <div class="ttv-logo-container mb-1">
          <img
            src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-santander.svg"
            alt="Logo Santander"
            width="130"
            height="23"
          />
        </div>
        <h5 class="fw-semibold m-0">Sólo desde cuentas Santander</h5>
        <ul class="text-secondary">
          <li>N° de cuenta: 005100892636</li>
          <li>Sucursal 71</li>
          <li>Caja de Ahorros USD</li>
          <li>Nombre: Diegal S.A</li>
        </ul>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <div class="ttv-logo-container mb-1">
          <img
            src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-brou.svg"
            alt="Logo BROU"
            width="110"
            height="30"
          />
        </div>
        <h5 class="fw-semibold m-0">Sólo desde cuentas BROU</h5>
        <ul class="text-secondary">
          <li>N° de cuenta: 110009060-00001</li>
          <li>Caja de Ahorros USD</li>
          <li>Nombre: Diegal S.A</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row row-cols-1 row-cols-lg-2 g-4 mt-0 mb-5">
    <div class="col">
      <!-- Tarjetas Santander -->
      <div
        class="border border-dark border-opacity-10 bg-white rounded-3 p-4 py-5 h-100 d-flex"
      >
        <div class="row gx-5 g-4 justify-content-md-between align-items-center">
          <div class="col-12 px-sm-5">
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-santander.svg"
              alt="Logo Santander"
              width="150"
              height="26"
            />
          </div>
          <div class="col-12 px-sm-5">
            <h4 class="m-0 lh-sm">
              Con tarjetas de Crédito Santander,
              <br class="d-none d-sm-block" />hasta 18 cuotas
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <!-- Puntos Soy Santander -->
      <div
        class="border border-dark border-opacity-10 bg-white rounded-3 p-4 py-5 h-100 d-flex"
      >
        <div class="row gx-5 g-4 justify-content-md-between align-items-center">
          <div class="col-12 px-sm-5">
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/banks/logo-soy-santander.svg"
              alt="Logo SoySantander"
              width="150"
              height="39"
            />
          </div>
          <div class="col-12 px-sm-5">
            <h4 class="m-0 lh-sm">
              Con Puntos Soy Santander, <br />
              contactá a tu vendedor y ahorrá con Puntos
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <!-- Tarjetas de Crédito -->
      <div
        class="border border-dark border-opacity-10 bg-white rounded-3 p-4 py-5 h-100 d-flex"
      >
        <div class="row gx-5 g-4 justify-content-md-between align-items-center">
          <div class="col-12 px-sm-5 d-flex gap-3 gap-md-4 align-items-center">
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/cards/logo-visa.svg"
              alt="Logo VISA"
              width="50"
              height="16"
            />
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/cards/logo-master-card.svg"
              alt="Logo MasterCard"
              width="38"
              height="23"
            />
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/cards/logo-oca.svg"
              alt="Logo OCA"
              width="50"
              height="16"
            />
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/cards/logo-amex.svg"
              alt="Logo American Express"
              width="55"
              height="14"
            />
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/cards/logo-prex.svg"
              alt="Logo Prex"
              width="50"
              height="16"
            />
          </div>
          <div class="col-12 px-sm-5">
            <h4 class="m-0 lh-sm">Otras tarjetas de crédito</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <!-- Abitab/RedPagos -->
      <div
        class="border border-dark border-opacity-10 bg-white rounded-3 p-4 py-5 h-100 d-flex"
      >
        <div class="row gx-5 g-4 justify-content-md-between align-items-center">
          <div class="col-12 px-sm-5 d-flex gap-4 align-items-center">
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/logo-abitab.svg"
              alt="Logo Abitab"
              width="100"
              height="28"
            />
            <img
              src="https://tocuyprodmedia.blob.core.windows.net/img/logos/logo-redpagos.svg"
              alt="Logo RedPagos"
              width="100"
              height="20"
            />
          </div>
          <div class="col-12 px-sm-5">
            <h4 class="m-0 lh-sm">En Abitab o Red Pagos</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
