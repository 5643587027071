  <div class="row g-0 align-items-center position-relative">
    <div class="col p-1">
      <app-airport-search 
        [(formInput)]="leg.origin" 
        [placeHolder]="'¿De dónde saldrás?'" 
        (formInputChange)="onOriginChange()"
        ngDefaultControl>
      </app-airport-search>
    </div>

    <div class="col-auto p-1 t-switch-directions">
      <a class="t-switch-directions mat-ripple" matRipple (click)="swapDestinations()">
        <i class="fa-regular fa-arrows-repeat"></i>
      </a>
    </div>

    <div class="col p-1">
      <app-airport-search 
        #destinationSearch
        [(formInput)]="leg.destination" 
        [placeHolder]="'¿A dónde querés viajar?'" 
        (formInputChange)="onDestinationChange()"
        ngDefaultControl>
      </app-airport-search>
    </div>
  </div>