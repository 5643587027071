import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './local-storage.service';
import { FlightSearchModel } from '../models/search/flight-search-leg';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private environment: EnvironmentService,
    private localStorageService: LocalStorageService
  ) { }

  saveFlightSearch(search: FlightSearchModel) {
    if (this.environment.isBrowser){
      this.localStorageService.set("ttv-lastSearch-flights", search)
      this.saveLastSearchedProduct("flight")
    }
  }

  retrieveLastFlightSearch(): FlightSearchModel | undefined{
    if (this.environment.isBrowser){
      return this.localStorageService.get("ttv-lastSearch-flights")
    }

    return undefined;
  }

  saveLastSearchedProduct(product: "flight" | "hotel" | "flightHotel" | "package" | "insurance") {
    if (this.environment.isBrowser){
      this.localStorageService.set("ttv-lastSearchedProduct", product)
    }
  }

  retrieveLastSearchProduct(): "flight" | "hotel" | "flightHotel" | "package" | "insurance" {
    if (this.environment.isBrowser){
      return this.localStorageService.get("ttv-lastSearchedProduct") || "flight"
    }

    return "flight";
  }
}
