import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DatePickerI18nService extends NgbDatepickerI18n {
  private readonly WEEKDAYS = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  private readonly MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  override getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    const widthStr = this.getWidthStr(width);
    return widthStr == 0 ? this.WEEKDAYS[weekday - 1] : this.WEEKDAYS[weekday - 1].substring(0, widthStr);
  }
  override getMonthShortName(month: number, year?: number): string {
    return this.MONTHS[month - 1].substring(0, 3)
  }
  override getMonthFullName(month: number, year?: number): string {
    return this.MONTHS[month - 1]
  }
  override getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day} ${this.getMonthFullName(date.month)} ${date.year}`;
  }

  constructor() {
    super();
  }

  private getWidthStr(width?: TranslationWidth): number {
    switch (width) {
      case TranslationWidth.Narrow:
        return 1
      case TranslationWidth.Short:
        return 2
      case TranslationWidth.Abbreviated:
        return 3
      case TranslationWidth.Wide:
        return 0
      default:
        return 0
    }
  }
}
