<div class="d-none d-lg-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-date-picker-button" mat-ripple>
    <i class="fa-regular fa-calendar fa-lg text-primary me-3"></i>
    <div class="d-flex fs-6 fw-semibold">
        <button class="date-button me-2" 
                [ngbPopover]="datePickerPopover" 
                triggers="manual" 
                #popOverFrom="ngbPopover"
                [placement]="displayMode === 'inline'? 'bottom' : 'right'"
                container="body"
                (click)="togglePopover()"
                [autoClose]="'outside'"
                [popoverClass]="'custom-popover'">
            {{ getDate(fromDate) | luxonFormat: "long" }}
        </button>
    </div>
</div>

<div class="d-lg-none d-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-date-picker-button" mat-ripple>
    <i class="fa-regular fa-calendar fa-lg text-primary me-3"></i>
    <div class="d-flex fs-6 fw-semibold">
        <button class="date-button me-2" (click)="showMobileDatepicker()">
            {{ getDate(fromDate) | luxonFormat: "long" }}
        </button>
    </div>
</div>

<ng-template #datePickerPopover let-popover="popover">
    <div class="popover-datepicker-container" (click)="$event.stopPropagation()">
        <ngb-datepicker 
            (dateSelect)="onDateSelection($event)" 
            [displayMonths]="2" 
            [dayTemplate]="single"
            navigation="arrows"
            outsideDays="visible" 
            [minDate]="_minDate"
            [maxDate]="_maxDate"
            [startDate]="startDate"
            triggers="manual"
            class="custom-datepicker"
            [weekdays]="translationWidth"
        ></ngb-datepicker>
    </div>
</ng-template>

<ng-template #single let-date let-focused="focused">
  <span 
    class="custom-day" 
    [class.focused]="focused" 
    [class.range]="isSelected(date)"
   >
    {{ date.day }}
  </span>
</ng-template>