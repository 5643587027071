import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private apiNewsletter = 'https://api.toctocviajes.com/api/newsletter/register';

  constructor(private http:HttpClient) {}

  PostSubscription(input:any): Observable<any> {
    return this.http.post<any>(this.apiNewsletter, input).pipe(
      map(_ => true),
      catchError(_error => of(false))
    )
  }
}
