import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorManagementService {

  constructor(public snackBar: MatSnackBar) { }

  showValidationErrors(message: string, action: string, duration = 4000) {
    const snack = this.snackBar.open(message, action, {
      duration: duration
    });
    return snack;
  }
}
