import { Component } from '@angular/core';

@Component({
  selector: 'app-filters-promotions-sheet',
  templateUrl: './filters-promotions-sheet.component.html',
  styleUrls: ['./filters-promotions-sheet.component.scss']
})
export class FiltersPromotionsSheetComponent {

}
