<!-- This banner is displayed in multiple locations and resolutions -->
<a mat-ripple href="https://toctocviajes.com/betoctoc" target="_blank"
class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-3 overflow-hidden text-dark">
<div class="d-grid p-3 pe-0">
  <div class="h5 fw-normal mb-2">
    ¡Enterate antes de las <br /> 
    mejores ofertas!
  </div>
  <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-1 py-1">
    Quiero suscribirme
    <i class="fa-solid fa-chevron-right fa-sm ms-1 mt-1"></i>
  </span>
</div>
<img class="position-absolute bottom-0 end-0 static-bg"
src="https://assets.toctocviajes.com/img/static-banners/img--banner-betoctoc.webp" alt="Enterate antes de las mejores ofertas con Betoctoc" />
</a>