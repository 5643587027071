import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-types',
  templateUrl: './payment-types.component.html',
  styleUrl: './payment-types.component.scss'
})
export class PaymentTypesComponent {

}
