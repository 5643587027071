<div class="container-lg pe-0">
  <div class="ttv-header-page position-relative w-100 pe-5">
    <h1 class="display-6 fw-semibold">Información relevante <br class="d-sm-none">para tu viaje</h1>
    <img class="ttv-img-smile" src="https://tocuyprodmedia.blob.core.windows.net/img/smile-hero-page--yellow.svg" alt="Yellow Smile">
  </div>
</div>

<div class="container-lg pb-5 mb-5">
  <div class="border border-dark border-opacity-10 bg-white rounded-3 p-4 py-5 mb-5">
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 gx-5 gy-5">
      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-memo-circle-check display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Verificación de datos</h5>
        <p class="text-secondary">
          Los nombres y apellidos que figuran en los tickets deben
          <strong>COINCIDIR EXACTAMENTE</strong> con los del documento de viaje
          (pasaporte o cédula). Por favor, verificá esto, ya que muchas
          aerolíneas no permiten el abordaje en caso de que haya alguna
          incongruencia, provocando la posible pérdida total del boleto.
        </p>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-passport display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Requisitos y vigencia de documentos</h5>
        <p class="text-secondary">
          Chequeá la VISAS, VACUNAS y otros requisitos que pueda pedir tu país
          de destino o escala y asegurate de contar con la documentación
          necesaria y en buen estado. La mayoría de los países requieren al
          menos 180 días de vigencia del documento de viaje al ingreso al mismo.
        </p>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-children display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Documentación para menores</h5>
        <p class="text-secondary">
          Si viajás con MENORES DE EDAD tené en cuenta que los mismos pueden
          necesitar documentación especial adicional como
          <a
            href="https://www.gub.uy/tramites/solicitud-partidas"
            target="_blank"
            class="text-primary"
          >
            partida de nacimiento
          </a>
          o
          <a
            href="https://www.gub.uy/tramites/permiso-menor-edad"
            target="_blank"
            class="text-primary"
          >
            permiso de menor</a
          >. Consultanos previamente a tu viaje la documentación necesaria para
          los mismos.
        </p>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-plane-slash display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Asistencia ante cambios</h5>
        <p class="text-secondary">
          Puede suceder que las compañías aéreas realicen algún cambio de vuelo
          o cancelación en lo que TocTocViajes no tiene influencia ni
          responsabilidad alguna ni por los gastos adicionales o inconvenientes
          que los mismos puedan generar a los pasajeros. Haremos todo lo que
          esté a nuestro alcance para obtener los mejores resultados para vos si
          eso sucede.
        </p>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-ticket-airline display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Condiciones de los tickets</h5>
        <p class="text-secondary">
          Los tickets aéreos, una vez emitidos,
          <strong>NO TIENEN DEVOLUCIÓN</strong>. Los cambios de vuelo, fecha o
          ruta y las eventuales devoluciones y/o anulaciones, totales o
          parciales, están sujetos a las condiciones previstas por cada línea
          aérea u operador y deberá abonarla el pasajero. Si un pasaje o
          servicio no se usa sin previo aviso, se pierde en su totalidad.
        </p>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-hand-holding-dollar display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Tasas turísticas: Pago al llegar</h5>
        <p class="text-secondary">
          Algunos destinos cobran tasas turísticas al pasajero al momento del
          ingreso o egreso del hotel. Las mismas no están incluidas en el precio
          final del paquete, teniendo que abonar el pasajero en destino.
        </p>
      </div>

      <div class="col d-flex flex-column gap-3 px-sm-5">
        <i class="fa-regular fa-tag display-5 text-primary"></i>
        <h5 class="fw-semibold m-0">Precios sujetos a disponibilidad</h5>
        <p class="text-secondary">
          Los precios de los vuelos y hoteles están sujetos a disponibilidad y
          cambio hasta el momento de la compra.
        </p>
      </div>
    </div>
  </div>
</div>
