<!-- Footer -->
<footer *ngIf="router.url != '/login'">

  <app-mobile-newsletter />

  <!--Footer_ Partners -->
  <div class="d-none d-sm-block our-partners bg-white border-bottom border-dark border-opacity-10 py-4 ">
    <div class="container px-4">
      <div class="d-flex align-items-center justify-content-between">
        <a href="https://uruguaynatural.com/" target="_blank">
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-uruguay-natural.svg" alt="Logo Uruguay Natural" width="165" height="38" />
        </a>
        <a href="https://www.santander.com.uy/" target="_blank">
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-santander.svg" alt="Logo Santander" width="186" height="33" />
        </a>
        <a href="https://aeropuertosuruguay.com.uy/" target="_blank">
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-aeropuertos-uruguay.svg" alt="Logo Aeropuertos Uruguay" width="173" height="44" />
        </a>
        <a href="https://www.assistcard.com/uy" target="_blank">
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-assist-card.svg" alt="Logo Assist Card" width="201" height="15" />
        </a>
      </div>
    </div>
  </div>
  <div class="bg-white py-5">
    <div class="container px-4">
      <div class="row">
        <!-- <div class="d-none d-xl-block col-xl-3">
          <a href="https://www.toctocviajes.com/">
            <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" alt="Logo TocTocViajes.com" width="173" height="63" class="mt-3" />
          </a>
        </div> -->
        <div class="col-12 col-xl">
          <mat-accordion class="t-mat-accordion-footer d-lg-flex justify-content-lg-between">
            <mat-expansion-panel class="shadow-none m-0">
              <mat-expansion-panel-header class="bg-transparent py-4">
                <mat-panel-title>
                  <h5 class="m-0">Acceso</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <li><a class="d-block text-black t-link-underline py-2" href="https://atlas.netviax.com/netviaxatlascontactos.sistemareclamocontacto.aspx?%20NVXATL,TTVUY,,,WEB,TOCTOC" title="Libro de reclamos" target="_blank">Libro de reclamos</a></li>
                <li><a class="d-block text-black t-link-underline py-2" href="https://atlas.netviax.com/netviaxatlascontactos.sistemaoportunidadcontacto.aspx?NVXATL,TTVUY,PCONS,TTV-OFF,WEB,TOCTOC" title="Consultas" target="_blank">Consultas</a></li>
                <li><a class="d-block text-black t-link-underline py-2" href="/mapa-del-sitio" title="Mapa del sitio" target="_blank">Mapa del sitio</a></li>
              </ul>
            </mat-expansion-panel>
            <mat-divider class="d-lg-none"></mat-divider>
            <mat-expansion-panel class="shadow-none m-0">
              <mat-expansion-panel-header class="bg-transparent py-4">
                <mat-panel-title>
                  <h5 class="m-0">Nuestros servicios</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="lh-sm">
                <li>
                  <a href="https://www.toctocviajes.com/flights" class="d-block text-black t-link-underline py-2">Vuelos</a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/hotels" class="d-block text-black t-link-underline py-2">Hoteles</a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/custom-package" class="d-block text-black t-link-underline py-2">
                    Vuelo + Hotel
                  </a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/boats" class="d-block text-black t-link-underline py-2">Buenos
                    Aires</a>
                </li>
                <li>
                  <a href="https://paquetes.toctocviajes.com/" class="d-block text-black t-link-underline py-2">Paquetes</a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/insurances" class="d-block text-black t-link-underline py-2">Asistencia</a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/betoctoc" class="d-block text-black t-link-underline py-2">BeTocToc</a>
                </li>
              </ul>
            </mat-expansion-panel>
            <mat-divider class="d-lg-none"></mat-divider>
            <mat-expansion-panel class="shadow-none m-0">
              <mat-expansion-panel-header class="bg-transparent py-4">
                <mat-panel-title>
                  <h5 class="m-0">
                    Sobre nosotros
                  </h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="lh-sm">
                <li>
                  <a href="https://www.toctocviajes.com/about" class="d-block text-black t-link-underline py-2">
                    Quiénes somos
                  </a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/terms" class="d-block text-black t-link-underline py-2">
                    Términos y condiciones
                  </a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/rrhh" class="d-block text-black t-link-underline py-2">
                    Unite a nuestro equipo
                  </a>
                </li>
                <li>
                  <a href="https://www.toctocviajes.com/mintur" class="d-block text-black t-link-underline py-2">
                    Agencia registrada
                  </a>
                </li>
              </ul>
            </mat-expansion-panel>
            <mat-divider class="d-lg-none"></mat-divider>
            <div>
              <h5 class="my-4">Estamos más cerca</h5>
              <ul class="d-flex flex-column lh-sm">
                <li>
                  <a href="https://goo.gl/maps/gWjZa6q9qC1aVz626" target="_blank" class="t-link-underline d-flex align-items-center gap-3 text-black lh-sm my-2">
                    <i class="fa-light fa-location-dot fa-lg flex-shrink-0"></i>
                    Av. Dr. Francisco Soca 1402
                  </a>
                </li>
                <li>
                  <a href="https://maps.app.goo.gl/YDG65E6sJWRYZAum6" target="_blank" class="t-link-underline t-link-underline d-flex align-items-center gap-3 text-black lh-sm my-2">
                    <i class="fa-light fa-location-dot fa-lg flex-shrink-0"></i>
                    Costa Urbana Shopping <br class="d-md-none d-lg-block">Nivel 2, Zona Norte, Local 53A
                  </a>
                </li>
                <li>
                  <a href="https://maps.app.goo.gl/tqo6oCRJETytQUfR6" target="_blank" class="t-link-underline t-link-underline d-flex align-items-center gap-3 text-black lh-sm my-2">
                    <i class="fa-light fa-location-dot fa-lg flex-shrink-0"></i>
                    General Paz 1452 esq. Caramurú
                  </a>
                </li>
                <li>
                  <a href="https://maps.app.goo.gl/q3m64zAanEDKmXXLA" target="_blank" class="t-link-underline t-link-underline d-flex align-items-center gap-3 text-black lh-sm my-2">
                    <i class="fa-light fa-location-dot fa-lg flex-shrink-0"></i>
                    Atlántico Shopping Punta del Este, <br class="d-md-none d-xl-block">subsuelo, local 003
                  </a>
                </li>
              </ul>
              <div class="d-flex gap-3 mt-4">
                <a href="https://www.instagram.com/toctocviajes/" target="_blank" mat-mini-fab aria-label="Seguinos en Instagram" class="bg-white shadow-none">
                  <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-instagram-white.svg" width="42" height="42" alt="Instagram">
                </a>
                <a href="https://www.facebook.com/toctocviajes" target="_blank" mat-mini-fab aria-label="Seguinos en Facebook" class="bg-white shadow-none">
                  <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-facebook-white.svg" width="42" height="42" alt="Facebook">
                </a>
                <a href="https://goo.gl/X7k0TJ" target="_blank" mat-mini-fab aria-label="Seguinos en LinkedIn" class="bg-white shadow-none">
                  <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-linkedin-white.svg" width="42" height="42" alt="Linkedin">
                </a>
                <a href="https://twitter.com/toctocviajes" target="_blank" mat-mini-fab aria-label="Seguinos en Twitter" class="bg-white shadow-none">
                  <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-x-twitter-white.svg" width="42" height="42" alt="X">
                </a>
              </div>
            </div>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer _ Copyright -->
  <div class="bg-light">
    <div class="container px-4">
      <div class="row align-items-center py-5 py-lg-3">
        <div class="d-flex col-12 col-lg-auto order-lg-last mb-4 mb-lg-0">
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-netviax-gray.svg" alt="Logo Netviax Solutions" width="97" height="25" class="order-lg-last me-4 me-lg-0" />
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-5m-travel-gray.svg" alt="Logo 5M Travel Group" width="158" height="21" class="order-lg-first me-lg-5" />
        </div>
        <div class="d-flex col-12 col-lg order-lg-first align-items-center">
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-iata-gray.svg" alt="Logo IATA" width="45" height="29" class="me-3" />
          <p class="copyright-text lh-sm m-0">
            <small>
              <strong>{{year}} - TocTocViajes.</strong> Todos los derechos reservados.
              Operador responsable Diegal S.A.
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>