import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements OnChanges {
  @Input('appFocus') shouldFocus: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    if (this.shouldFocus) {
      this.el.nativeElement.focus();
    }
  }
}
