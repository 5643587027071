import { FormControl } from "@angular/forms";
import { DateTime } from "luxon";
import { CabinType, TravelType } from "src/app/models/flights/flight-utilities";
import { PassengerSearch } from "src/app/models/passenger-search";

export class FlightSearchLegVM {
  origin = new FormControl();
  destination = new FormControl();
  departDate: Date;
  arrivalDate: Date;
  isOneWay = false;

  constructor() {
    this.departDate = new Date(new Date(Date.now() + 2 * 24 * 60 * 60 * 1000));
    this.arrivalDate = new Date(new Date(Date.now() + 6 * 24 * 60 * 60 * 1000));
  }
}

export class FlightSearchVM {
  simpleLeg = new FlightSearchLegVM()
  legs: FlightSearchLegVM[] = []
  flexibileDates = new FormControl()
  isMultipleLegSearch = false
  passengers = { adults: 1, childrens: 0, infants: 0, cabinType: CabinType.All } as PassengerSearch;

  private _type = TravelType.RoundTrip
  get type() {
    return this._type
  }
  set type(t: TravelType) {
    this._type = t
    this.isMultipleLegSearch = false
    if (this._type === TravelType.MultipleFlights) this.isMultipleLegSearch = true
  }

  constructor() {
    const leg = new FlightSearchLegVM()
    leg.isOneWay = true
    this.legs = [leg]
  }

  getSearchURL() {
    switch (this.type) {
      case TravelType.OneWay:
        return this.getOneWaySearchURL();
      case TravelType.RoundTrip:
        return this.getRoundTripSearchURL();
      case TravelType.MultipleFlights:
        return this.getMultipleFlightsSearchURL();
      default:
        return this.getRoundTripSearchURL();
    }
  }

  getRoundTripSearchURL() {
    // TODO: Revisar que son los parametros que fue el texto
    return `${this.simpleLeg.origin.value.code}/`
      + `${this.simpleLeg.destination.value.code}/`
      + `${this.simpleLeg.departDate.toISOString().slice(0, 10)}/`
      + `${this.simpleLeg.arrivalDate.toISOString().slice(0, 10)}/`
      + `false/`
      + `${this.passengers.adults}/`
      + `${this.passengers.childrens}/`
      + `${this.passengers.infants}/`
      + `false/`
      + `0/`
      + `4/`
      + `1/`
      + `/`
      + `/`
      + `list`
  }

  getOneWaySearchURL() {
    // TODO: Revisar que son los parametros que fue el texto
    return `${this.simpleLeg.origin.value.code}/`
      + `${this.simpleLeg.destination.value.code}/`
      + `${this.simpleLeg.departDate.toISOString().slice(0, 10)}/`
      + `false/`
      + `${this.passengers.adults}/`
      + `${this.passengers.childrens}/`
      + `${this.passengers.infants}/`
      + `false/`
      + `0/`
      + `4/`
      + `1/`
      + `/`
      + `/`
      + `list`
  }

  getMultipleFlightsSearchURL() {
    // TODO: Revisar que son los parametros que fue el texto
    return ``
  }

  validateSearch(): any {
    if (this.type === TravelType.MultipleFlights) {
      throw new Error('Method not implemented.');
    }

    return this.validateSimpleSearch()
  }

  validateSimpleSearch(): any {

    let valid = true;
    let message = "";

    let departCode = null;
    if (this.simpleLeg.origin.value) departCode = this.simpleLeg.origin.value.code;
    let arrivalCode = null;
    if (this.simpleLeg.destination.value) arrivalCode = this.simpleLeg.destination.value.code;

    if (this.type === TravelType.RoundTrip) {
      if (!departCode) {
        message = "Por favor, ingresá tu ciudad de origen";
        valid = false;
      } else if (!arrivalCode) {
        message = "Por favor, ingresá tu ciudad de destino";
        valid = false;
      } else if (!this.simpleLeg.departDate) {
        message = "Por favor, seleccioná la fecha de salida";
        valid = false;
      } else if (!this.simpleLeg.arrivalDate) {
        message = "Por favor, seleccioná la fecha de llegada";
        valid = false;
      } else if (departCode === arrivalCode) {
        message = "El origen y el destino no pueden ser iguales";
        valid = false;
      } else if (
        !((DateTime.fromJSDate(this.simpleLeg.arrivalDate).diff(DateTime.fromJSDate(this.simpleLeg.departDate), "days")).days >= 0)
      ) {
        message = "La fecha de salida debe ser menor a la de llegada";
        valid = false;
      }
    } else if (this.type === TravelType.OneWay) {
      if (!departCode) {
        message = "Por favor, ingresá tu ciudad de origen";
        valid = false;
      } else if (!arrivalCode) {
        message = "Por favor, ingresá tu ciudad de destino";
        valid = false;
      } else if (!this.simpleLeg.departDate) {
        message = "Por favor, seleccioná la fecha de salida";
        valid = false;
      } else if (departCode === arrivalCode) {
        message = "El origen y el destino no pueden ser iguales";
        valid = false;
      }
    }

    if (valid) {
      if (this.passengers.adults < this.passengers.infants) {
        message = "La cantidad de bebés no puede ser mayor a la de adultos";
        valid = false;
      }
    }

    return {
      valid: valid,
      message: message
    };
  }

}