import { AfterViewInit, Component, inject, ElementRef, OnDestroy, ViewChild, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { TranslationWidth } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DatePickerRangeMobileComponent } from './date-picker-range-mobile/date-picker-range-mobile.component';
import { LuxonFormatPipe } from 'src/app/pipes/luxon-format.pipe';
import { EnvironmentService } from 'src/app/services/environment.service';

export enum DateSelecting {
	FromDate,
	ToDate
}

@Component({
	selector: 'app-date-picker-range',
	templateUrl: './date-picker-range.component.html',
	styleUrl: './date-picker-range.component.scss'
})
export class DatePickerRangeComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() displayMode: "inline" | "block" = "inline";
	startDate: NgbDate = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
	calendar = inject(NgbCalendar);
	model: NgbDateStruct | undefined;

	hoveredDate: NgbDate | null = null
	fromDate: NgbDate = this.calendar.getToday();
	toDate: NgbDate | null = this.calendar.getNext(this.fromDate, 'd', 10);
	_departDate: Date = new Date()
	_arrivalDate: Date = new Date()
	isClickOnFirst: boolean = false
	_minDate: NgbDateStruct = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());
	_maxDate: NgbDateStruct = new NgbDate((new Date()).getFullYear(), (new Date()).getMonth() - 1, (new Date()).getDay());

	translationWidth: TranslationWidth = TranslationWidth.Abbreviated;
	mutationObserver: MutationObserver | undefined;

	dateSelecting: DateSelecting = DateSelecting.FromDate;

	@Input()
	hasMinDate = false

	@Input()
	set minDate(value: Date) {
		if (value)
			this._minDate = {
				day: value.getDate(),
				month: value.getMonth() + 1,
				year: value.getFullYear()
			};
	}

	@Input()
	set departDate(value: Date) {
		if (value) {
			if (value >= new Date()) {
				this._departDate = value;
				this.fromDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
				this.startDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
			}
		}
	}
	@Output()
	departDateChange = new EventEmitter<Date>();

	@Input()
	set arrivalDate(value: Date) {
		if (value) {
			if (value > new Date()) {
				this._arrivalDate = value;
				this.toDate = new NgbDate(value.getFullYear(), value.getMonth() + 1, value.getDate());
			}
		}
	}
	@Output()
	arrivalDateChange = new EventEmitter<Date>();

	@ViewChild('popOverTo', { static: false })
	popOverTo!: NgbPopover

	@ViewChild('popOverFrom', { static: false })
	popOverFrom!: NgbPopover

	setFocus() {
		if (this.env.isMobile) {
			this.showMobileDatepicker()
		} else {
			this.togglePopover('from')
		}
	}

	constructor(
		private elementRef: ElementRef,
		private bottomSheet: MatBottomSheet,
		private luxonDate: LuxonFormatPipe,
		private env: EnvironmentService
	) { }

	isSelectingFromDate() {
		return this.dateSelecting === DateSelecting.FromDate;
	}

	ngOnInit() {
		let daysRange = 328
		if (!this.hasMinDate) {
			const __minDate = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
			this._minDate = {
				day: __minDate.getDate(),
				month: __minDate.getMonth() + 1,
				year: __minDate.getFullYear()
			};
			daysRange = 330
		}

		const __maxDate = new Date(Date.now() + daysRange * 24 * 60 * 60 * 1000);
		this._maxDate = {
			day: __maxDate.getDate(),
			month: __maxDate.getMonth() + 1,
			year: __maxDate.getFullYear()
		};

		this.hoveredDate = this.toDate;
		
		if (this.getDaysDiff() <= 0) {
			this.toDate = this.calendar.getNext(this.fromDate, 'd', 10);
		}
	}

	ngAfterViewInit(): void {
		this.mutationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
					this.modifyDatepickerButtons();
				}
			});
		});

		this.mutationObserver.observe(this.elementRef.nativeElement, {
			childList: true,
			subtree: true,
		});
	}

	modifyDatepickerButtons() {
		const prevButton = document.querySelector('button[aria-label="Previous month"]') as HTMLElement;
		const nextButton = document.querySelector('button[aria-label="Next month"]') as HTMLElement;

		if (prevButton) {
			prevButton.setAttribute('aria-label', 'Mes anterior');
			prevButton.setAttribute('title', 'Mes anterior');
		}

		if (nextButton) {
			nextButton.setAttribute('aria-label', 'Mes siguiente');
			nextButton.setAttribute('title', 'Mes siguiente');
		}
	}

	onDateSelection(date: NgbDate) {
		const _date = new Date(date.year, date.month - 1, date.day);
		if (!this.fromDate && !this.toDate && this.isClickOnFirst) {
			this.fromDate = date;
			this._departDate = _date
			this.departDateChange.emit(_date)
			this.dateSelecting = DateSelecting.ToDate;
			this.popOverFrom.close()
			this.popOverTo.open()
			this.startDate = date
		} else if (
			this.fromDate &&
			(!this.toDate || !this.isClickOnFirst) &&
			(date.after(this.fromDate) || date.equals(this.fromDate))
		) {
			this.toDate = date;
			this._arrivalDate = _date
			this.arrivalDateChange.emit(_date)
			this.popOverTo.close()
		} else {
			this.toDate = null;
			this.fromDate = date;
			this._departDate = _date;
			this.dateSelecting = DateSelecting.ToDate;
			this.departDateChange.emit(_date);
			this.popOverFrom.close();
			this.popOverTo.open();
			this.startDate = date;
		}
	}

	getDate(date: NgbDate | null) {
		if (!date) return new Date();
		return new Date(date.year, (date.month - 1), date.day);
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			date.after(this.fromDate) &&
			date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			date.equals(this.toDate) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	getDaysDiff() {
		if (this.fromDate && this.toDate) {
			const from = DateTime.fromObject({ year: this.fromDate.year, month: this.fromDate.month, day: this.fromDate.day });
			const to = DateTime.fromObject({ year: this.toDate.year, month: this.toDate.month, day: this.toDate.day });
			const diff = to.diff(from, 'days').days;
			return diff >= 0 ? diff : 0;
		}

		return 0;
	}

	togglePopover(popover: string) {
		if (popover === 'to')
			if (this.popOverTo.isOpen()) {
				this.popOverTo.close();
			} else {
				this.isClickOnFirst = false
				this.dateSelecting = DateSelecting.ToDate;
				this.popOverTo.open();
			}
		else
			if (this.popOverFrom.isOpen()) {
				this.popOverFrom.close();
			} else {
				this.isClickOnFirst = true
				this.dateSelecting = DateSelecting.FromDate;
				this.popOverFrom.open();
			}
	}

	ngOnDestroy() {
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
		}
	}

	onMouseLeave() {
		this.hoveredDate = new NgbDate(
			this._arrivalDate.getFullYear(),
			this._arrivalDate.getMonth() + 1,
			this._arrivalDate.getDate()
		);
	}

	getArrivalDate() {
		if (this.fromDate && this.toDate) {
			if (this.toDate.after(this.fromDate)) {
				return this.luxonDate.transform(this.getDate(this.toDate), "short")
			}
		}

		return "Vuelta"
	}

	showMobileDatepicker() {
		const datepickerBottomSheet = this.bottomSheet.open(DatePickerRangeMobileComponent, {
			data: {
				minDate: this._minDate,
				maxDate: this._maxDate,
				departDate: this._departDate,
				arrivalDate: this._arrivalDate,
				clickOnFirst: true
			},
			panelClass: "g-datepicker-mobile-container"
		});

		datepickerBottomSheet.afterDismissed().subscribe(data => {
			if (data) {
				if (data.datepickerMode === "range") {
					const _startDate = new Date(
						data.startDate.year,
						data.startDate.month - 1,
						data.startDate.day
					);
					this.departDateChange.emit(_startDate);
				}
			}
		});
	}

	isSelected(date: NgbDate) {
		return this.fromDate.equals(date);
	}
}
