import { Component } from '@angular/core';

@Component({
  selector: 'app-travel-info',
  templateUrl: './travel-info.component.html',
  styleUrl: './travel-info.component.scss'
})
export class TravelInfoComponent {

}
