import { Component } from '@angular/core';

@Component({
  selector: 'app-betoctoc-static-banner',
  templateUrl: './betoctoc-static-banner.component.html',
  styleUrls: ['./betoctoc-static-banner.component.scss']
})
export class BetoctocStaticBannerComponent {

}
