import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Observable, filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { AirportService } from 'src/app/services/airport.service';
import { AirportSearch, AirportData } from '../models/airport-search.model';
import { SearchFocusService } from 'src/app/services/search-focus.service';

@Component({
  selector: 'app-airport-search-mobile',
  templateUrl: './airport-search-mobile.component.html',
  styleUrl: './airport-search-mobile.component.scss'
})
export class AirportSearchMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  formInput = new FormControl();
  title: string = '';
  groupedAirports: AirportSearch[] = [];
  airports!: Observable<AirportSearch[]>;

  @ViewChild('inputElement') inputElement!: ElementRef;
  
  constructor(
    private bottomSheetRef: MatBottomSheetRef<AirportSearchMobileComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private cityAutocomplete: AirportService
  ) {}

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.focus();
  }

  ngOnInit() {
    this.airports = this.createOptionsObservable(this.formInput);
    this.title = this.data.title;
  }

  createOptionsObservable(control: FormControl): Observable<AirportSearch[]> {
    return control.valueChanges.pipe(
      filter(text => typeof text === "string" || text instanceof String),
      filter(text => text.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(token => {
        return this.cityAutocomplete.getCityAirportsOptions(token);
      })
    );
  }

  selectItem(airport: AirportData) {
    this.bottomSheetRef.dismiss({
      value: airport
    });
  }

  getDisplay(elem?: AirportData) {
    return elem ? elem.name : undefined;
  }

  getCode(elem?: AirportData) {
    return elem ? elem.code : undefined;
  }

  getCityName(elem?: AirportData) {
    return elem ? elem.name : undefined;
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  spinning() {
    if (typeof this.formInput.value === "string" && this.formInput.value.length) return true;
    else return false;
  }

  getTitle() {
    return this.title;
  }

  getLowerTitle() {
    return this.title.toLowerCase();
  }

  ngOnDestroy() {}
}
