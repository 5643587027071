import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, tap, catchError, retry, take } from "rxjs/operators";
import { Airport, MultipleAirports } from "../models/airport.model";
import { EnvironmentService } from "./environment.service";
import * as settings from "../settings"
import { AirportData, AirportSearch } from "../components/airport-search/models/airport-search.model";

@Injectable({
  providedIn: "root"
})
export class AirportService {
  private apiUrl: string;
  constructor(private http: HttpClient, private environment: EnvironmentService) {
    this.apiUrl = this.environment.getValue<string>(settings.API_URL);
  }
  getAutocompleteOptions(search: string): Observable<Airport[]> | undefined {
    if (!search) return of([]);
    if (this.environment.isBrowser)
      return this.http
        .get<Airport[]>(`${this.apiUrl}AirportsAndRegions/GetAirports?search=${search}`)
        .pipe(
          map(items => items.filter(i => !i.subitem)),
          map(items => items.slice(0, 5)),
          map(items => {
            items.map(s => {
              s.name = s.name.replace("|||", " cerca de ");
              return s;
            });
            return items;
          })
        );
    return of([]);
  }  

  printitems(items : any): boolean{
    console.log("items", items)
    return true
  }

  getCityAirportsOptions(search: string | String): Observable<AirportSearch[]> {
    if (!search) return of([]);
    if (this.environment.isBrowser)
      return this.http
        .get<AirportData[]>(
          `${this.apiUrl}autocompletes/citiesandairports/${search}`
        )
        .pipe(
          // Accede a items.result y verifica que es un array antes de aplicar slice
          map(items => {
            // Transformar la lista de AirportData en una lista de AirportSearch
            return items.map((item: AirportData) => {
              let airportSearch: AirportSearch = {
                cityAirport: { ...item }, // Clonamos el objeto item como cityAirport
                airports: [] // Inicializamos el array de airports
              };
              
              // Procesar el nombre del cityAirport
              let ar = airportSearch.cityAirport.name.split("|||");
              airportSearch.cityAirport.name = ar[0];
              if (ar.length > 1) airportSearch.cityAirport.closeRegionId = ar[1];
              airportSearch.cityAirport.name = airportSearch.cityAirport.name.replace("(Todos los aeropuertos)", "");
  
              // Añadir al array de airports
              airportSearch.airports.push({
                ...airportSearch.cityAirport,
                name: airportSearch.cityAirport.name.split("(")[0].trim() // Modificar nombre del aeropuerto
              });
  
              // Retornar el objeto transformado
              return airportSearch;
            });
          }),
          catchError(err => {
            console.error(err);
            return of([]);
          })
        );
  
    return of([]);
  }
  
  
 

  getAirportByCode(code: string): Observable<Airport> {
    // if (!code) return of();
    if (this.environment.isBrowser)
      return this.http
        .get<Airport>(`${this.apiUrl}AirportsAndRegions/GetAirportByCode?code=${code}`)
        .pipe(
          take(1),
          retry(5)
        );

    return of()
  }

  getAirportsByCode(destinations: any): Observable<MultipleAirports> {
    if (!destinations) return of();
    if (this.environment.isBrowser)
      return this.http
        .get<MultipleAirports>(
          `${this.apiUrl}AirportsAndRegions/GetAirportsByCode?${this.buildAirportsQuery(
            destinations
          )}`
        )
        .pipe(
          take(1),
          retry(5)
        );
    
    return of()
  }

  private buildAirportsQuery(destinations: any) {
    let query = "leg1.origin=" + destinations.leg1.origin;
    query += "&leg1.destination=" + destinations.leg1.destination;
    if (destinations.leg2.origin && destinations.leg2.destination) {
      query += "&leg2.origin=" + destinations.leg2.origin;
      query += "&leg2.destination=" + destinations.leg2.destination;
    }
    if (destinations.leg3.origin && destinations.leg3.destination) {
      query += "&leg3.origin=" + destinations.leg3.origin;
      query += "&leg3.destination=" + destinations.leg3.destination;
    }
    return query;
  }
}
