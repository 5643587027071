import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlightSearchVM } from '../../flight-search-vm';

@Component({
  selector: 'app-flights-simple-search',
  templateUrl: './flights-simple-search.component.html',
  styleUrl: './flights-simple-search.component.scss'
})
export class FlightsSimpleSearchComponent {
  @Input() flightSearch: FlightSearchVM = new FlightSearchVM();
  @Input() displayMode: "inline" | "block" = "inline";
  @Output() flightSearchChange = new EventEmitter<FlightSearchVM>();

  @Input() enableFocusDestination = false;

  constructor(){
    
  }
}
