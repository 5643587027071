export enum TravelType {
    OneWay,
    RoundTrip,
    MultipleFlights
}

export enum CabinType {
    All = 1,
    Tourist = 2,
    Business = 3,
    FirstClass = 4
}
