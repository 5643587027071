import { Component } from '@angular/core';
import { OfferService } from '../promotions/components/offer/services/offer.service';
import { Observable, of } from 'rxjs';
import { Product } from '../promotions/components/offer/models/product.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  hide = true;
  mainCampaign: any;
  offer$: Observable<Product[]> | undefined;
  index: any;
  offer : any;
  contentLoaded = false;
  http: any;
  offers: any;
  Carouseloffer$: Observable<Product[]> | undefined;

  constructor( private offerService: OfferService) {}

  ngOnInit(): void {

    this.offerService.getHomeOffersCarousel().subscribe((CarouselOffers: Product[]) => {
      this.Carouseloffer$ = of(CarouselOffers);
    });

    this.offerService.getProducts().subscribe((offers: Product[]) => {
      this.offer$ = of(offers);
    });

    setTimeout(() => {
      this.contentLoaded = true;
    }, 1500);
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
