import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchFocusService {

  constructor() { }
  
  private destinationSelected = new Subject<void>();
  
  destinationSelected$ = this.destinationSelected.asObservable();

  completeDestination() {
    this.destinationSelected.next();
  }
}
