  <div class="row align-items-center">

      <div class="col-12 col-sm-6 col-lg-auto p-1">
        <app-flights-simple-search [(flightSearch)]="flightSearch"></app-flights-simple-search>
      </div>

    <div class="col-12 col-sm-6 col-lg-auto p-1">
      <ng-container *ngIf="isOnlyWay()">
        <app-single-date-picker
          #datePicker
          [departDate]="flightSearch.simpleLeg.departDate"
          (departDateChange)="onDepartDateChange($event)"
          [displayMode]="displayMode"
        ></app-single-date-picker>
      </ng-container>
      <ng-container *ngIf="isRoundTrip()">
        <app-date-picker-range
          #datePickerRange
          [departDate]="flightSearch.simpleLeg.departDate"
          [arrivalDate]="flightSearch.simpleLeg.arrivalDate"
          (departDateChange)="onDepartDateChange($event)"
          (arrivalDateChange)="onArrivalDateChange($event)"
          [displayMode]="displayMode"
        ></app-date-picker-range>
    </ng-container>
    <ng-container *ngIf="isMultipleDestination()">
      MULTIPLE DESTINO
      <!-- <app-date-picker-range
        #datePickerRange
        [(departDate)]="flightSearch.simpleLeg.departDate"
        [(arrivalDate)]="flightSearch.simpleLeg.arrivalDate"
        [displayMode]="displayMode"
      ></app-date-picker-range> -->
  </ng-container>
    </div>

    <div class="col-12 col-sm-6 col-lg-auto p-1">
      <app-passengers-counter-panel #passengerCounterPanel [(passengers)]="flightSearch.passengers"></app-passengers-counter-panel>
    </div>

    <div class="col-12 col-lg-1 order-lg-2 p-1 mt-4 mt-lg-0">
      <button mat-flat-button color="accent" class="t-search-button rounded-3 px-4 w-100" (click)="search()">
        Buscar
      </button>
    </div>
  </div>