<div class="container-xxl pt-4 pb-5">
  <div class="row d-flex align-items-center justify-content-center gap-5 mb-5">
    <!-- Banner Promo Backend Desktop/Mobile - promotionImageUrlMobile-->
    <div
      class="col-12 col-sm-6 col-md-4 col-xxl-3 pe-xxl-0"
      *ngIf="
        mainCampaign?.isPromotionActive ||
        mainCampaign?.isPromotionWithoutDiscount
      "
    >
      <div class="t-banner-promo rounded-3 overflow-hidden">
        <img
          src="{{ mainCampaign?.promotionImageUrl }}"
          alt="Banner promo mobile"
          class="d-sm-none w-100"
        />
        <img
          src="{{ mainCampaign?.promotionImageMobileUrl }}"
          alt="Banner promo desktop"
          class="d-none d-sm-block w-100"
        />
      </div>
    </div>

    <div class="col-12 col-lg col-xxl ps-lg-0 text-center text-lg-start">
      <!-- <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-promo.svg" alt="Promotions"
        class="t-icon-promo-il me-4 mb-2 mb-sm-3"> -->
      <div>
        <h1 class="h2 mb-2">¡Ofertas que no podés dejar pasar!</h1>
        <p class="d-none d-sm-block fs-5 text-secondary">
          Elegí a dónde querés viajar ¡y ahorrá!
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- Left col Desktop -->
    <div class="col-12 col-lg-4 col-xxl-3 pe-xxl-0">
      <div class="d-none d-lg-block">
        <!-- Filters -->
        <div
          class="bg-white border border-dark border-opacity-10 p-4 rounded-3"
        >
          <app-filters-promotions></app-filters-promotions>
        </div>
        <!-- Banner Betoctoc -->
        <div class="my-3">
          <app-betoctoc-static-banner></app-betoctoc-static-banner>
        </div>
        <!-- Banner Packages -->
        <div class="my-3">
          <app-packages-static-banner></app-packages-static-banner>
        </div>
        <!-- Banner Puntos Santander -->
        <div
          class="t-banner-puntos-soy w-100 bg-white border border-dark border-opacity-10 rounded-3 p-3 overflow-hidden"
        >
          <span class="fs-5 fw-bold text-danger lh-1">
            Hasta 18 cuotas sin recargo <br />
            con tarjetas
            <img
              src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg"
              width="109"
              height="19"
              alt="Santander"
              class="mb-2"
            />
          </span>
          <hr class="my-3 w-25" />
          <span class="d-block fw-semibold text-dark mb-2">
            ¿Tenés Puntos Soy Santander?
          </span>
          <small class="text-secondary">
            ¡Usalos para comprar tu viaje <br />
            y ahorrá!
          </small>
        </div>
      </div>
    </div>

    <!-- Right Col -->
    <div class="col-12 col-lg-8 col-xxl-9 ps-lg-2 ps-xxl-5">
      <!-- Banner Santander -->
      <div class="mb-4">
        <div
          class="t-banner-soy-18 text-sm-center bg-white border border-dark border-opacity-10 rounded-3 p-3 overflow-hidden"
        >
          <span class="fw-bold fs-5 text-danger lh-1">
            Hasta 18 cuotas sin recargo <br class="d-md-none" />
            con tarjetas de crédito
            <img
              src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg"
              width="109"
              height="19"
              alt="Santander"
              class="mb-2"
            />
          </span>
        </div>
      </div>

      <div>
        <!-- OFFER COMPONENT -->
        <app-offer />
        <!-- ---------- -->
      </div>

      <!-- Banner to Contact -->
      <div
        class="col-12 bg-white p-3 py-5 border border-dark border-opacity-10 rounded-3 text-center mb-5"
      >
        <img
          src="https://assets.toctocviajes.com/img/static-banners/img-smile-agent-call-center.svg"
          width="280"
          alt="¿No encontraste lo que buscabas?"
          class="d-inline-flex mx-auto"
        />
        <h3 class="fs-4 my-4">¿No encontraste lo que buscabas?</h3>
        <a href="https://atlas.netviax.com/netviaxatlascontactos.sistemaoportunidadcontacto.aspx?NVXATL,TTVUY,PCONS,TTV-OFF,WEB,TOCTOC" mat-flat-button color="primary" class="mx-auto">
          Consultá con nuestros asesores
        </a>
      </div>
    </div>
  </div>
</div>

<!-- Mobile floating filter CTA -->
<div
  class="t-mobile-floating-filter d-flex d-lg-none justify-content-center position-fixed start-0 bottom-0 w-100 p-3 pt-5"
>
  <button mat-raised-button color="primary" (click)="openSheet()">
    Filtrar <span class="text-warning">(2)</span>
  </button>
</div>
