import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightSearchBarComponent } from './flight-search-bar/flight-search-bar.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialsModule } from '../materials/materials.module';
import { FlightsSearchLegComponent } from './flight-search-bar/flights-search-leg/flights-search-leg.component';
import { FlightsSimpleSearchComponent } from './flight-search-bar/flights-simple-search/flights-simple-search.component';
import { PassengersCounterPanelComponent } from '../components/searchers/passengers-counter-panel/passengers-counter-panel.component';
import { PassengersCounterPanelMobileComponent } from '../components/searchers/passengers-counter-panel/passengers-counter-panel-mobile/passengers-counter-panel-mobile.component';
import { FlightSearchBarHomeComponent } from './flight-search-bar-home/flight-search-bar-home.component';



@NgModule({
  declarations: [
    FlightSearchBarHomeComponent,
    FlightSearchBarComponent,
    FlightsSearchLegComponent,
    FlightsSimpleSearchComponent,
    PassengersCounterPanelComponent,
    PassengersCounterPanelMobileComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialsModule
  ],
  exports: [
    FlightSearchBarHomeComponent,
    FlightSearchBarComponent,
    FlightsSearchLegComponent,
    FlightsSimpleSearchComponent,
    PassengersCounterPanelComponent,
    PassengersCounterPanelMobileComponent
  ]
})
export class FlightsModule { }
