<!-- FLIGHTS---------------------------------------------------------------- -->
<h5 class="d-inline-flex fw-normal mb-2 ms-n2">¿A dónde querés viajar?</h5>
<!-- search-flights-bar-------------------------------------------- -->

<!-- flights-roundtrip-search----------------------- -->
<div class="row">
  <div class="col-12 col-lg-auto order-lg-3 mb-3 mb-lg-0 px-1">
    <!-- Tabs travel type -->
    <!-- Revisar https://material.angular.io/components/tabs/examples para ver que opción utilizar -->

    <!-- <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start" class="t-flights-searcher-tabs">
          <mat-tab label="Ida y Vuelta"></mat-tab>
          <mat-tab label="Sólo ida"></mat-tab>
          <mat-tab label="Multidestino"></mat-tab>
        </mat-tab-group>     -->
    <nav mat-tab-nav-bar [tabPanel]="tabPanel" animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start"
      class="t-flights-searcher-tabs">
      <a mat-tab-link *ngFor="let link of links" (click)="onTravelTypeSelected(link.travelType)"
        [active]="activeLink == link.travelType">
        {{link.description}}
      </a>
    </nav>
  </div>

  <mat-tab-nav-panel #tabPanel>

  <div class="col-12 col-lg-12 order-lg-1">
    <ng-container [ngSwitch]="activeLink">
      <app-flight-search-bar [flightSearch]="flightSearch" [travelType]="TravelType.RoundTrip" *ngSwitchCase="TravelType.RoundTrip"></app-flight-search-bar>
      <app-flight-search-bar [flightSearch]="flightSearch" [travelType]="TravelType.OneWay" *ngSwitchCase="TravelType.OneWay"></app-flight-search-bar>
      <app-flight-search-bar [flightSearch]="flightSearch" [travelType]="TravelType.MultipleFlights" *ngSwitchCase="TravelType.MultipleFlights"></app-flight-search-bar>

    </ng-container>
  </div>
</mat-tab-nav-panel>

  <div class="col-12 col-lg order-lg-4 mt-3 mt-lg-0 ps-lg-5">
    <div class="row align-items-center justify-content-end">
      <div class="col-12 col-lg-auto p-1 me-lg-3">
        <div class="fw-semibold text-success">¡Mejorá tu precio si tenes fechas flexibles!</div>
      </div>
      <div class="col-12 col-lg-auto p-1">
        <mat-checkbox color="primary" class="ms-n2">Buscar en fechas cercanas</mat-checkbox>
      </div>
    </div>
  </div>
</div>
<!-- flights-oneway-search------------------------- -->
<!-- flight-multicity-search------------------------ -->
<div class="row d-none">
  <div class="col-12 col-lg-auto order-lg-3 mb-3 mb-lg-0">
    <!-- Tabs travel type -->
    <!-- Revisar https://material.angular.io/components/tabs/examples para ver que opción utilizar -->

    <!-- <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start" class="t-flights-searcher-tabs">
          <mat-tab label="Ida y Vuelta"></mat-tab>
          <mat-tab label="Sólo ida"></mat-tab>
          <mat-tab label="Multidestino"></mat-tab>
        </mat-tab-group>     -->
    <!-- <nav mat-tab-nav-bar animationDuration="0ms" mat-stretch-tabs="false"
          mat-align-tabs="start" class="t-flights-searcher-tabs">
          <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link" [active]="activeLink == link">
            {{link}}
          </a>
        </nav> -->
  </div>

  <div class="col-12">
    <div class="row">

      <div class="col-12 col-lg-9 pe-lg-5">
        <!-- Tramo 1 -->
        <div class="row align-items-center">
          <div class="col-12 d-md-none d-flex justify-content-between px-1">
            <span class="fw-semibold lh-sm py-2"><small>Tramo 1</small></span>
            <span class="fw-semibold text-danger lh-sm py-2">
              <i class="fa-regular fa-minus me-1"></i><small>Eliminar</small>
            </span>
          </div>
          <div class="col-12 col-md-8 col-xxl-9 position-relative">
            <div class="row">
              <div class="col-12 col-sm-6 p-1">
                <!-- app-airport-search -->
                <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                  <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                  <input type="text" matInput placeholder="Origen" class="fs-6 fw-semibold text-truncate" />
                </mat-form-field>
                <!-- FIN - app-airport-search -->
              </div>

              <a class="t-switch-directions" mat-ripple>
                <i class="fa-regular fa-arrows-repeat"></i>
              </a>

              <div class="col-12 col-sm-6 p-1">
                <!-- app-airport-search -->
                <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                  <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                  <input type="text" matInput placeholder="Destino" class="fs-6 fw-semibold text-truncate" />
                </mat-form-field>
                <!-- FIN - app-airport-search -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md p-1">
            <!-- <app-date-range-picker></app-date-range-picker> -->
          </div>
          <div class="d-none d-md-block col-auto p-1">
            <button mat-stroked-button color="warn" class="t-btn-delete-flight">
              <i class="fa-regular fa-xmark fa-lg"></i>
            </button>
          </div>
          <mat-divider class="border-3 border-light d-lg-none mt-3 mb-2"></mat-divider>
        </div>
        <!-- Tramo 2 -->
        <div class="row align-items-center">
          <div class="col-12 d-md-none d-flex justify-content-between px-1">
            <span class="fw-semibold lh-sm py-2"><small>Tramo 2</small></span>
            <span class="fw-semibold text-danger lh-sm py-2">
              <i class="fa-regular fa-minus me-1"></i><small>Eliminar</small>
            </span>
          </div>
          <div class="col-12 col-md-8 col-xxl-9 position-relative">
            <div class="row">
              <div class="col-12 col-sm-6 p-1">
                <!-- app-airport-search -->
                <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                  <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                  <input type="text" matInput placeholder="Origen" class="fs-6 fw-semibold text-truncate" />
                </mat-form-field>
                <!-- FIN - app-airport-search -->
              </div>

              <a class="t-switch-directions" mat-ripple>
                <i class="fa-regular fa-arrows-repeat"></i>
              </a>

              <div class="col-12 col-sm-6 p-1">
                <!-- app-airport-search -->
                <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                  <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                  <input type="text" matInput placeholder="Destino" class="fs-6 fw-semibold text-truncate" />
                </mat-form-field>
                <!-- FIN - app-airport-search -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md p-1">
            <!-- <app-date-range-picker></app-date-range-picker> -->
          </div>
          <div class="d-none d-md-block col-auto p-1">
            <button mat-stroked-button color="warn" class="t-btn-delete-flight">
              <i class="fa-regular fa-xmark fa-lg"></i>
            </button>
          </div>
          <mat-divider class="border-3 border-light d-lg-none mt-3 mb-2"></mat-divider>
        </div>
        <!-- Tramo 3 -->
        <div class="row align-items-center">
          <div class="col-12 d-md-none d-flex justify-content-between px-1">
            <span class="fw-semibold lh-sm py-2"><small>Tramo 3</small></span>
            <span class="fw-semibold text-danger lh-sm py-2">
              <i class="fa-regular fa-minus me-1"></i><small>Eliminar</small>
            </span>
          </div>
          <div class="col-12 col-md-8 col-xxl-9 position-relative">
            <div class="row">
              <div class="col-12 col-sm-6 p-1">
                <!-- app-airport-search -->
                <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                  <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                  <input type="text" matInput placeholder="Origen" class="fs-6 fw-semibold text-truncate" />
                </mat-form-field>
                <!-- FIN - app-airport-search -->
              </div>

              <a class="t-switch-directions" mat-ripple>
                <i class="fa-regular fa-arrows-repeat"></i>
              </a>

              <div class="col-12 col-sm-6 p-1">
                <!-- app-airport-search -->
                <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                  <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                  <input type="text" matInput placeholder="Destino" class="fs-6 fw-semibold text-truncate" />
                </mat-form-field>
                <!-- FIN - app-airport-search -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md p-1">
            <!-- <app-date-range-picker></app-date-range-picker> -->
          </div>
          <div class="d-none d-md-block col-auto p-1">
            <button mat-stroked-button color="warn" class="t-btn-delete-flight">
              <i class="fa-regular fa-xmark fa-lg"></i>
            </button>
          </div>
          <mat-divider class="border-3 border-light d-lg-none mt-3 mb-2"></mat-divider>
        </div>

        <!-- + Agregar tramo -->
        <div class="row py-2">
          <div class="col-12 p-1">
            <button mat-stroked-button color="primary" class="align-self-end d-flex w-100 t-btn-add-flight">
              <i class="fa-solid fa-plus me-1"></i>
              Agregar tramo
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <div class="row row-cols-1 align-items-center">
          <div class="col p-1">
            <!-- <app-passengers-counter-panel></app-passengers-counter-panel> -->
          </div>
          <div class="col p-1">
            <button mat-flat-button color="accent" class="t-search-button rounded-3 px-4 w-100">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>