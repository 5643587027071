import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-filters-promotions',
  templateUrl: './filters-promotions.component.html',
  styleUrls: ['./filters-promotions.component.scss']
})
export class FiltersPromotionsComponent implements OnInit {
  selectedRadioValueProduct: string = '10';
  selectedRadioValueRegion: string = '1';

  productQueryParamMap: { [key: string]: string } = {
    'vuelo': '11',
    'hotel': '12',
    'vuelo-hotel': '13',
    //'paquete': '14',
  };

  regionQueryParamMap: { [key: string]: string } = {
    'latinoamerica': '2',
    'america-del-norte': '3',
    'brasil': '4',
    'caribe': '5',
    'europa': '6',
    'asia': '7',
    'oceania': '8',
    'africa': '9',
  };

  constructor(private route: ActivatedRoute, private router: Router, private bottomSheet: MatBottomSheet) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        switchMap(params => {
          const productQueryParam = params['product'];
          const regionQueryParam = params['region'];

          // Set default values if query parameters are missing
          this.selectedRadioValueProduct = this.productQueryParamMap[productQueryParam] || '10';
          this.selectedRadioValueRegion = this.regionQueryParamMap[regionQueryParam] || '1';

          return this.route.queryParams;
        })
      )
      .subscribe();
  }

  onRadioChangeProduct(selectedValue: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { product: selectedValue },
      queryParamsHandling: 'merge'
    });
  }

  onRadioChangeRegion(selectedValueRegion: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { region: selectedValueRegion },
      queryParamsHandling: 'merge'
    });
  }

  closeSheet() {
    this.bottomSheet.dismiss();
  }
}
