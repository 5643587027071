import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import * as settings from "../settings"

@Injectable({
  providedIn: 'root'
})
export class LegacyRouterService {

  private apirUrl: string;

  constructor(
    private env: EnvironmentService) {
      this.apirUrl = this.env.getValue<string>(settings.LEGACY_TTV_URL);
  }

  redirectToFlightRoundTrip(url: string) {
    const flightUrl = `${this.apirUrl}flights/round-trip/${url}`;
    window.location.href = flightUrl;
  }

  redirectToFlightOneWay(url: string) {
    const flightUrl = `${this.apirUrl}flights/one-way/${url}`;
    window.location.href = flightUrl;
  }
  
}
