import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AirportSearchComponent } from '../components/airport-search/airport-search.component';
import { MaterialsModule } from '../materials/materials.module';
import { LuxonFormatPipe } from "../pipes/luxon-format.pipe";
import { NgbDatepickerModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerRangeComponent } from '../components/date-picker-range/date-picker-range.component';
import { DatePickerRangeMobileComponent } from '../components/date-picker-range/date-picker-range-mobile/date-picker-range-mobile.component';
import { AirportSearchMobileComponent } from '../components/airport-search/airport-search-mobile/airport-search-mobile.component';
import { SingleDatePickerMobileComponent } from '../components/date-picker/date-picker-mobile/single-date-picker-mobile.component';
import { SingleDatePickerComponent } from '../components/date-picker/single-date-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FocusDirective } from '../directives/focus.directive';



@NgModule({
  declarations: [
    AirportSearchComponent,
    AirportSearchMobileComponent,
    DatePickerRangeComponent,
    DatePickerRangeMobileComponent,
    SingleDatePickerComponent,
    SingleDatePickerMobileComponent,
    FocusDirective
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    ReactiveFormsModule,
    LuxonFormatPipe,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbModule
],
  exports: [
    AirportSearchComponent,
    AirportSearchMobileComponent,
    NgbPopoverModule,
    DatePickerRangeComponent,
    DatePickerRangeMobileComponent,
    SingleDatePickerComponent,
    SingleDatePickerMobileComponent
  ],
  providers: [LuxonFormatPipe]
})
export class SharedModule { }
