import { Component } from '@angular/core';

@Component({
  selector: 'app-packages-static-banner',
  templateUrl: './packages-static-banner.component.html',
  styleUrls: ['./packages-static-banner.component.scss']
})
export class PackagesStaticBannerComponent {

}
