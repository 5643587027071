import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrl: './site-map.component.scss'
})
export class SiteMapComponent implements OnInit {
  seletedTabIndex: number = 0

  constructor(
    private route: ActivatedRoute,
    private router: Router
  )
  {}

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      const path = url[1].path
      this.seletedTabIndex = this.getTabIndexFromPath(path)
    })
  }

  getTabIndexFromPath(path: string): number {
    switch (path) {
      case 'vuelos':
        return 0;
      case 'hoteles':
        return 1;
      case 'paquetes':
        return 2;
      default:
        return 0;
    }
  }

  onTabChange(event: number): void {
    this.router.navigate([this.getPathFromTabIndex(event)])
  }

  getPathFromTabIndex(index: number): string {
    switch (index) {
      case 0:
        return 'mapa-del-sitio/vuelos';
      case 1:
        return 'mapa-del-sitio/hoteles';
      case 2:
        return 'mapa-del-sitio/paquetes';
      default:
        return 'mapa-del-sitio/vuelos';
    }
  }


}
