<div class="mobile-passengers-counter">
    <h4 class="mb-3">Pasajeros</h4>
    <!-- Adultos -->
    <div class="row align-items-center">
      <div class="col">
        <div class="mat-body-2 text-black lh-1 mb-1">Adultos</div>
      </div>
      <div class="col-5">
        <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
          <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" 
            (click)="subtract('adult')" [disabled]="isButtonDisabled('adults', 'minus')">
            <i class="fa-regular fa-minus"></i>
          </button>
          <div class="text-center">
            <span>{{ changingPassengers.adults }}</span>
          </div>
          <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" 
            (click)="add('adult')" [disabled]="isButtonDisabled('adults', 'plus')">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  
    <!-- Niños -->
    <div class="row align-items-center my-3">
      <div class="col">
        <div class="mat-body-2 text-black lh-1 mb-1">Niños</div>
        <div class="mat-small text-black-50">Entre 2 y 11 años</div>
      </div>
      <div class="col-5">
        <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
          <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" 
            (click)="subtract('children')" [disabled]="isButtonDisabled('children', 'minus')">
            <i class="fa-regular fa-minus"></i>
          </button>
          <div class="text-center">
            <span>{{ changingPassengers.childrens }}</span>
          </div>
          <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" 
            (click)="add('children')" [disabled]="isButtonDisabled('children', 'plus')">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  
    <!-- Bebés -->
    <div class="row align-items-center">
      <div class="col">
        <div class="mat-body-2 text-black lh-1 mb-1">Bebés</div>
        <div class="mat-small text-black-50">Menores de 2 años</div>
      </div>
      <div class="col-5">
        <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
          <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" 
            (click)="subtract('infants')" [disabled]="isButtonDisabled('infant', 'minus')">
            <i class="fa-regular fa-minus"></i>
          </button>
          <div class="text-center">
            <span>{{ changingPassengers.infants }}</span>
          </div>
          <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3" 
            (click)="add('infants')" [disabled]="isButtonDisabled('infant', 'plus')">
            <i class="fa-regular fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  
    <!-- Selección de Cabina -->
    <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages my-3">
      <span matPrefix class="ps-3">
        <i class="fa-regular fa-seat-airline fa-lg text-primary me-2"></i>
      </span>
      <mat-select [(value)]="changingPassengers.cabinType" disableRipple>
        <mat-option *ngFor="let cabin of getCabinTypes()" [value]="cabin">
          {{ getCabinTypeName(cabin) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <!-- Botones de Cancelar y Aplicar -->
    <div class="d-flex justify-content-between border-top border-opacity-25 py-3">
      <button mat-stroked-button class="w-100" (click)="cancelPassengers()">Cancelar</button>
      <button mat-flat-button color="primary" class="w-100" [disabled]="isApplyButtonDisabled()" (click)="applyPassengers()">
        Aplicar
      </button>
    </div>
  </div>
  