<!-- <div class="d-none d-lg-block ms-1" clickOutside (clickOutside)="clickedOutside()">
  <button mat-stroked-button class="mx-1 rounded-pill px-3" (click)="toggleMenu()">
    <i class="fa-regular fa-envelope"></i>
  </button>
  <div *ngIf="isMenuOpened" class="desktop-newsletter mt-3" [formGroup]="form">
    <ng-container *ngIf="!subscribed">
      <div class="text-center p-3">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-promo.svg" width="60px" height="74"
          alt="Inicio de sesión" class="mb-3" />
        <h2 class="d-inline-flex fs-5 mb-4">¡No te pierdas las mejores promos para viajar!</h2>
        <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages mb-2">
          <input type="email" formControlName="email" matInput placeholder="Ej. juan.carlos@correo.com">
        </mat-form-field>
        <button (click)="submit(form.value)" mat-flat-button color="primary" class="w-100">Suscribirme</button>
      </div>
    </ng-container>
    <ng-container *ngIf="subscribed">
      <div class="text-center p-3 mt-5">
        <i class="fa-regular fa-circle-check fa-2xl text-success mb-4"></i>
        <h2 class="fs-5 mb-2">¡Felicitaciones!</h2>
        <p class="fs-6 lh-sm text-secondary"><small>Vas a estar recibiendo en tu mail las mejores promos para viajar, tips toc y mucho más.</small>
        </p>
      </div>
    </ng-container>
  </div>
</div> -->


<button mat-stroked-button class="mx-1 rounded-pill px-3" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
  <i class="fa-regular fa-envelope"></i>
</button>
<mat-menu #menu="matMenu" xPosition="before" class="mt-3">
  <div (click)="$event.stopPropagation()" [formGroup]="form">

    <ng-container *ngIf="!subscribed">
      <div class="text-center p-3">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-promo.svg" width="60px"
          height="74" alt="Inicio de sesión" class="mb-3" />
        <h2 class="d-inline-flex fs-5 mb-4">¡No te pierdas las mejores promos para viajar!</h2>
        <mat-form-field appearance="outline" class="t-mat-form-field mb-1">
          <input type="email" matInput placeholder="Ingresá tu email" formControlName="email">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Por favor, ingresa un email válido.
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            El email es requerido
          </mat-error>
        </mat-form-field>
        <button (click)="submit(form.value)" mat-flat-button color="primary" class="w-100">Suscribirme</button>
        <button (click)="closeMenu()" mat-stroked-button class="w-100 mt-2">No, me lo pierdo</button>
      </div>
    </ng-container>

    <ng-container *ngIf="subscribed">
      <div class="text-center p-3">
        <i class="fa-regular fa-circle-check fa-2xl text-success mb-4"></i>
        <h2 class="fs-5 mb-2">¡Felicitaciones!</h2>
        <p class="fs-6 lh-sm text-secondary">
          <small>
            Vas a estar recibiendo en tu mail las mejores promos para viajar, tips toc y mucho más.
          </small>
        </p>
        <button (click)="closeMenu()" mat-flat-button color="primary" class="w-100 mt-4">¡Genial! gracias</button>
      </div>
    </ng-container>
  </div>
  
</mat-menu>