import { Component } from '@angular/core';

@Component({
  selector: 'app-flights-home',
  templateUrl: './flights-home.component.html',
  styleUrl: './flights-home.component.scss'
})
export class FlightsHomeComponent {

}
