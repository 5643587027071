import { Component } from '@angular/core';
import { OfferService } from './components/offer/services/offer.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FiltersPromotionsComponent } from './components/filters-promotions/filters-promotions.component';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent {
  mainCampaign: any;
  offerBanner: any;

  constructor(private offerService: OfferService, private bottomSheet: MatBottomSheet) {
  }

  ngOnInit(): void {
    this.offerService.ReadMainCampaign().subscribe((response) => {
      this.mainCampaign = response;
    });

    this.offerService.Read().subscribe((response) => {
      this.offerBanner = response.offers.filter((offer: { hasPromotion: boolean; }) => offer.hasPromotion === true);
    });
  }

  openSheet() {
    const componentInstance = this.bottomSheet.open(FiltersPromotionsComponent);
    componentInstance.afterDismissed().subscribe(x => {
      this.offerService.ScrollTop();
    });
  }
}
