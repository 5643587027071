import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { NewsletterService } from './../../pages/promotions/shared/services/newsletter/newsletter.service';

@Component({
  selector: 'app-mobile-newsletter',
  templateUrl: './mobile-newsletter.component.html',
  styleUrls: ['./mobile-newsletter.component.scss']
})
export class MobileNewsletterComponent {
  form!: FormGroup;
  subscribed: boolean | undefined;

  constructor(public fb: FormBuilder, private newsletter: NewsletterService) {
    this.subscribed = false;
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  submit(form: any) {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.newsletter.PostSubscription(this.form.value).subscribe((response) => {
      this.subscribed = true;
      }
    );
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
}
