/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { Settings } from 'luxon';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';


function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
  .catch(err => console.error(err));
  };


 if (document.readyState === 'complete') {
   bootstrap();
 } else {
   document.addEventListener('DOMContentLoaded', bootstrap);
 }

 if (environment.production) {
  enableProdMode();
}
 
Settings.defaultLocale = 'es';