<!-- Banner Backend - Type: Promo (Mobile - Tablet)-->
<!-- <div class="bg-white p-3 d-lg-none">
  <a href="" target="_blank" class="t-banner-promo d-block rounded-4 overflow-hidden">
    <img src="https://tocuyprodmedia.blob.core.windows.net/img/banner-promo-small.jpg" alt="Banner promo small"
      class="w-100 d-md-none">
    <img src="https://tocuyprodmedia.blob.core.windows.net/img/banner-promo-large.jpg" alt="Banner promo small"
      class="w-100 d-none d-md-block">
  </a>
</div> -->

<!-- Navbar Home Mobile (< 1200px) -->
<div class="bg-white d-lg-none">
  <nav class="t-navbar-mobile">
    <div class="w-100 d-flex">
      <button mat-ripple class="t-active">
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-flights-product-light fa-lg"></i>
        </div>
        <small>Vuelos</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-hotels-product-light fa-lg"></i>
        </div>
        <small>Hoteles</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-dp-product-light fa-lg"></i>
        </div>
        <small>Vuelo + Hotel</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-boats-product-light fa-lg"></i>
        </div>
        <small>Buenos Aires</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-packages-product-light fa-lg"></i>
        </div>
        <small>Paquetes</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-insurances-product-light fa-lg"></i>
        </div>
        <small>Asistencia</small>
      </button>
      <button mat-ripple>
        <div class="t-navbar-mobile_product-icon">
          <i class="fa-kit fa-promotions-product-light fa-lg"></i>
        </div>
        <small>Promociones</small>
      </button>
    </div>
  </nav>
</div>

<!-- Buscadores -->
<div class="bg-white border-bottom border-secondary border-opacity-10 pt-3 pt-lg-4 mb-lg-5">
  <div class="container-xxl px-sm-4 px-xxl-0">
    <div class="t-searchers-container mb-lg-n5">
      <ng-content></ng-content>
    </div>

    <div class="t-searchers-container d-none">
      <!-- HOTELS---------------------------------------------------------------- -->
      <h2 class="d-inline-flex fw-normal mb-2 ms-n2">Encontrá tu alojamiento perfecto</h2>
      <!-- search-hotels-bar ----------------------------------------------->
      <div class="row">
        <div class="col-12 col-lg-11">
          <div class="row">
            <div class="col-12 col-lg p-1">
              <!-- app-location-search -->
              <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                <input type="text" matInput placeholder="¿Dónde querés hospedarte? Hotel, Ciudad, País..." class="fs-6 fw-semibold text-truncate" />
              </mat-form-field>
              <!-- FIN - app-location-search -->
            </div>

            <div class="col-12 col-lg-auto p-1">
              <!-- date-range-picker -->
              <!-- <app-date-range-picker></app-date-range-picker> -->
              <!-- FIN - date-range-picker -->
            </div>

            <div class="col-12 col-lg-auto p-1">
              <!-- Rooms -->
              <app-rooms-counter-panel></app-rooms-counter-panel>
              <!-- FIN - Rooms -->
            </div>
          </div>
        </div>

        <div class="col-12 order-lg-3 mt-3 mt-lg-0">
          <div class="row align-items-center">
            <div class="col-12 col-lg-auto px-1 py-3 me-lg-3">
              <div class="text-success fw-semibold lh-sm">Hasta 30% de descuento en tu Vuelo + Hotel </div>
            </div>
            <div class="col-12 col-lg-auto px-1 py-3">
              <a href="" class="text-primary fw-semibold lh-sm">
                <i class="fa-solid fa-magnifying-glass me-1"></i>Buscar en Vuelo + Hotel
              </a>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-1 p-1 mt-4 mt-lg-0">
          <button mat-flat-button color="accent" class="t-search-button rounded-4 px-4 w-100">
            Buscar
          </button>
        </div>
      </div>
    </div>

    <div class="t-searchers-container d-none">
      <!-- VUELO + HOTEL---------------------------------------------------------------- -->
      <div class="d-sm-flex align-items-sm-center justify-content-sm-between justify-content-md-start ms-n2">
        <h2 class="fw-normal d-inline-flex mb-2 me-3">Armá tu viaje a medida</h2>
        <span class="d-inline-flex bg-success text-white fw-bold px-2 rounded-pill lh-lg mb-2">
          <small>Hasta 30% OFF</small>
        </span>
      </div>
      <!-- search-dp-bar------------------------------------------------- -->
      <div class="row">
        <div class="col-12 col-lg-11">
          <div class="row">
            <div class="col-12 col-lg position-relative">
              <div class="row">
                <div class="col-12 col-sm-6 p-1">
                  <!-- app-airport-search -->
                  <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                    <i class="fa-regular fa-location-crosshairs fa-lg text-primary me-3"></i>
                    <input type="text" matInput placeholder="¿De dónde saldrás?" class="fs-6 fw-semibold text-truncate" />
                  </mat-form-field>
                </div>

                <a class="t-switch-directions" mat-ripple>
                  <i class="fa-regular fa-arrows-repeat"></i>
                </a>

                <div class="col-12 col-sm-6 p-1">
                  <!-- app-airport-search -->
                  <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                    <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                    <input type="text" matInput placeholder="¿A dónde querés viajar?" class="fs-6 fw-semibold text-truncate" />
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-lg-auto p-1">
              <!-- <app-date-range-picker></app-date-range-picker> -->
            </div>

            <div class="col-12 col-sm-6 col-lg-auto p-1">
              <app-rooms-counter-panel></app-rooms-counter-panel>
            </div>
          </div>
        </div>

        <div class="col-12 order-lg-3">
          <div class="row align-items-start">
            <div class="col-12 col-lg-4 p-1 me-lg-5">
              <mat-checkbox color="primary" class="ms-n2">Quiero Hotel en otra ciudad</mat-checkbox>
              <!-- app-location-search -->
              <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages mb-3">
                <i class="fa-regular fa-location-dot fa-lg text-primary me-3"></i>
                <input type="text" matInput placeholder="¿Dónde querés hospedarte? Hotel, Ciudad, País..." class="fs-6 fw-semibold text-truncate" />
              </mat-form-field>
              <!-- FIN - app-location-search -->
            </div>
            <div class="col-12 col-lg-auto p-1">
              <mat-checkbox color="primary" class="ms-n2">Quiero Hotel solo por unos días</mat-checkbox>
              <div class="mb-3">
                <!-- <app-date-range-picker></app-date-range-picker> -->
              </div>

            </div>
          </div>
        </div>

        <div class="col-12 col-lg-1 p-1 mt-4 mt-lg-0">
          <button mat-flat-button color="accent" class="t-search-button rounded-4 px-4 w-100">
            Buscar
          </button>
        </div>
      </div>
    </div>

    <div class="t-searchers-container d-none">
      <!-- INSURANCES---------------------------------------------------------------- -->
      <div class="d-flex align-items-center justify-content-between justify-content-md-start ms-n2 mb-2">
        <h5 class="d-inline-flex fw-normal m-0">Viajá más tranquilo</h5>
        <img src="https://assets.toctocviajes.com/img/logos/partners/logo-assist-card-red-v.svg" alt="Logo Assist Card" width="70" height="5" class="d-inline-flex d-sm-none">
        <img src="https://assets.toctocviajes.com/img/logos/partners/logo-assist-card-red-h.svg" alt="Logo Assist Card" width="135" height="10" class="d-none d-sm-inline-flex ms-3 mt-1">
      </div>
      <!-- search-insurances-bar----------------------------------------- -->
      <div class="row pb-3">
        <div class="col-12 col-lg-11">
          <div class="row">
            <div class="col-12 col-lg p-1">
              <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">
                <i class="fa-regular fa-location-crosshairs fa-lg text-primary me-3"></i>
                <mat-select class="fs-6 fw-semibold">
                  <mat-option value="Africa">África</mat-option>
                  <mat-option value="AmericaDelNorte">América del Norte</mat-option>
                  <mat-option value="Asia">Asia</mat-option>
                  <mat-option value="Caribe">Caribe</mat-option>
                  <mat-option value="Europa">Europa</mat-option>
                  <mat-option value="AmericaLatina">América Latina</mat-option>
                  <mat-option value="Oceania">Oceanía</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12 col-sm-6 col-lg-auto p-1">
              <!-- <app-date-range-picker></app-date-range-picker> -->
            </div>

            <div class="col-12 col-sm-6 col-lg-auto p-1">
              <!-- <app-passengers-counter-panel></app-passengers-counter-panel> -->
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-1 p-1 mt-4 mt-lg-0">
          <button mat-flat-button color="accent" class="t-search-button rounded-4 px-4 w-100">
            Buscar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container px-4 py-4 py-md-5">
  <h1 class="text-center text-xl-start mt-5 mb-0">✈️ Vuelos baratos, hoteles, paquetes y mucho más...</h1>
</div>

<!-- Banner Backend - Type: Flight / V+H / Hotel -->
<div class="container px-4 pt-5">
  <a href="" target="_blank" class="t-highlight-offer t-overlay position-relative d-block rounded-4 overflow-hidden">
    <img class="t-img-bg" src="https://tocuyprodmedia.blob.core.windows.net/img/background-offer-backend.jpg" alt="Background backend"><!-- home.mainCustomizedOffer.imageUrl -->

    <div class="t-highlight-offer_content position-relative text-white p-3 p-lg-5">
      <div class="pb-3">
        <div class="d-flex align-items-center mb-2">
          <h2 class="display-6 fw-semibold lh-1 m-0">Punta Cana</h2><!--{{home.offers[0].title}} -->
          <i class="fa-kit fa-dp-product-regular fs-1 ms-2"></i><!-- home-offer-icon offertype="{{home.offers[0].offerType}}" -->
        </div>
        <p class="fw-light w-75">
          ¡Conocé aguas turquesas y lo mejor del Caribe!
        </p>
      </div>

      <div class="w-100 d-flex align-items-end justify-content-between d-md-grid gap-3 pt-lg-5">
        <div class="fs-6 lh-sm fw-semibold text-uppercase pe-3">
          Vuelo + 5 Noches de Hotel
        </div>
        <div class="text-end tex-md-left d-grid gap-2 d-md-flex align-items-center">
          <div class="fs-6 lh-1 text-decoration-line-through order-md-2">USD 1250</div>
          <div class="d-flex align-items-center fs-3 lh-1 fw-semibold order-md-1">
            <span class="fs-6 me-1 fw-bold">USD</span>1200
          </div>
        </div>
      </div>
    </div>
  </a>
  <hr class="border-secondary border-opacity-25 my-4 my-sm-5">
</div>

<!-- Banner Backend - Type: Promo (Desktop)-->
<!-- <div class="container-xl pt-sm-5 d-none d-lg-block">
  <a href="" target="_blank" class="t-banner-promo d-block rounded-4 overflow-hidden">
    <img src="https://tocuyprodmedia.blob.core.windows.net/img/banner-promo-large.jpg" alt="Banner promo large"
      class="w-100">
  </a>
</div> -->

<!-- Static Banners XS-SM-MD -->
<div class="container d-lg-none px-4 pb-5">
  <div class="row d-flex flex-column gap-3">
    <!-- Static Banners > to /promociones -->
    <div class="col-12">
      <a mat-ripple href="" target="_blank" class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 pe-0">
          <div class="h5 fw-normal mb-2">
            ¡Ofertas que no podés<br />dejar pasar!
          </div>
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-1 py-1">
            Ver ofertas
            <i class="fa-solid fa-chevron-right fa-sm ms-1 mt-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-promos.webp" alt="Ofertas que no podes dejar pasar" />
      </a>
    </div>
    <!-- Static Banners > Betoctoc -->
    <div class="col-12">
      <app-betoctoc-static-banner></app-betoctoc-static-banner>
    </div>
    <!-- Static Banners > CallCenter -->
    <div class="col-12">
      <a mat-ripple href="" target="_blank" class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 pe-0">
           <div class="h5 fw-normal mb-2">
            ¿Querés hablar con<br />nuestros asesores?
          </div>
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-1 py-1">
            ¡Llamanos!
            <i class="fa-solid fa-chevron-right fa-sm ms-1 mt-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-call-center-agent.webp" alt="Agente de viajes" />
      </a>
    </div>
  </div>
  <hr class="border-secondary border-opacity-25">
  <div class="row">
    <!-- Banner mobile Santander -->
    <div class="col-12">
      <div class="text-sm-center bg-white border border-secondary border-opacity-10 rounded-4 p-3 overflow-hidden t-banner-soy-18">
        <span class="fs-6 text-dark lh-1">
          Hasta 18 cuotas sin recargo <br class="d-md-none"> con tarjetas de crédito
          <img src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" width="100" height="17" alt="Santander" class="mb-2" />
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Static Bnners LG -->
<section class="container d-none d-lg-block px-4 pb-5">
  <div class="row row-cols-3 g-4">
    <!-- Static Bnners LG > Assist Card -->
    <div class="col">
      <a mat-ripple href="" target="_blank" class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 p-sm-4 pe-0">
          <div class="h5 fw-normal mb-2">Viajá con la mejor Asistencia</div>
          <img src="https://assets.toctocviajes.com/img/logos/partners/logo-assist-card.svg" width="148" height="11" alt="Assist Card" />
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-1 py-1">
            Ver más
            <i class="fa-solid fa-chevron-right fa-sm ms-1 mt-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-assist-card.webp" alt="Viajá con la mejor Asistencia" />
      </a>
    </div>
    <!-- Static Bnners LG > Banner Packages -->
    <div class="col">
      <a mat-ripple href="" target="_blank" class="t-static-banner t-packages-b d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="d-grid p-3 p-sm-4 pe-0">
          <div class="h5 fw-normal mb-2">
            Conocé nuestra sección<br /> de paquetes
          </div>
          <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-1 py-1">
            Ver paquetes
            <i class="fa-solid fa-chevron-right fa-sm ms-1 mt-1"></i>
          </span>
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-packages-home.webp" alt="Conocé nuestra sección de Paquetes" />
      </a>
    </div>
    <!-- Static Bnners LG > Banner Santander -->
    <div class="col">
      <div class="t-static-banner d-flex bg-white position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden text-dark">
        <div class="p-3 p-sm-4 pe-0">
          <div class="h5 fw-normal mb-3">Hasta 18 cuotas sin recargo<br>con tarjetas de crédito</div>
          <img src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" width="125" height="22" alt="Santander" />
        </div>
        <img class="position-absolute bottom-0 end-0 static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-santander.webp" alt="Hasta 18 cuotas sin recargo con tarjetas de crédito" />
      </div>
    </div>
  </div>
</section>

<!-- Offers Grid -->
<section class="container d-none d-lg-block px-4 py-sm-5">
  <div class="row">
    <div class="col-12 col-lg">
      <h2 class="mb-5">📢 Ofertas pensadas para vos</h2>
      <div class="row">
        <!-- Offers Grid > Featured Offer -->
        <div class="col-lg-4 col-xl-6 mb-4">
          <ng-container>
            <div *ngFor="let offer of offer$ | async, trackBy trackByFn, let index = index;  let first = first ;let last = last;">
              <ngx-skeleton-loader *ngIf="!contentLoaded" class="" count="1" appearance="line" animation="progress-dark" [theme]="{
              height: '400px',
              width: '640px',
              'border-radius': '1rem'
            }"></ngx-skeleton-loader>
              <div *ngIf="first" class="t-rounded-top-left">
                <a href="{{ offer.url }}" target="_blank" class="offer t-offer-card" *ngIf="contentLoaded">
                  <div mat-ripple class="t-offer-card_content t-highest-card t-rounded-top-left overflow-hidden position-relative overflow-hidden pt-3" *ngIf="contentLoaded">
                    <img *ngIf="contentLoaded" ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" height="400" width="640" fetchpriority="high" />
                    <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-4 pt-5 lh-1">
                      <div class="pt-5 pe-3">
                        <div class="t-offer-card_description">
                          <h3 class="h4">{{ offer.title }}</h3>
                          <div class="lh-sm text-white mb-2">
                            {{ offer.descriptionLine }}
                          </div>
                        </div>
                        <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                          {{offer.offerTypeCustomText }}
                        </div>
                      </div>
                      <div class="d-block text-end">
                        <div class="fs-6 lh-1 text-decoration-line-through mb-2">USD {{ offer.priceOld }}</div>
                        <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                          <span class="fs-6 me-1 fw-bold">USD</span>{{ offer.priceNew }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Offers Grid > Offer -->
        <div class="col-lg-4 col-xl-3 mb-4">
          <ng-container>
            <div *ngFor="let offer of offer$ | async, trackBy trackByFn, let index = index;  let first = first ;let last = last;">
              <ngx-skeleton-loader *ngIf="!contentLoaded" class="" count="1" appearance="line" animation="progress-dark" [theme]="{
            height: '350px',
            width: '295px',
            'border-radius': '8px'
          }"></ngx-skeleton-loader>
              <div *ngIf="index === 1">
                <a href="{{ offer.url }}" target="_blank" class="offer t-offer-card" *ngIf="contentLoaded">
                  <div mat-ripple class="mat-ripple t-offer-card_content t-highest-card overflow-hidden position-relative rounded-4 pt-3" *ngIf="contentLoaded">
                    <img *ngIf="contentLoaded" ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" height="350" width="295" fetchpriority="high" />

                    <!-- Messagge Promo -->
                    <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-4 pt-5 lh-1">
                      <div class="pt-5 pe-3">
                        <div class="t-offer-card_description">
                          <h3 class="h4">{{ offer.title }}</h3>
                          <div class="lh-sm text-white mb-2">
                            {{ offer.descriptionLine }}
                          </div>
                        </div>
                        <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                          {{offer.offerTypeCustomText }}
                        </div>
                      </div>
                      <div class="d-block text-end">
                        <div class="fs-6 lh-1 text-decoration-line-through mb-2">USD {{ offer.priceOld }}</div>
                        <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                          <span class="fs-6 me-1 fw-bold">USD</span>{{ offer.priceNew }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Offers Grid > Carrousel Offer -->
        <div class="col-lg-4 col-xl-3 mb-4">
          <div *ngIf="Carouseloffer$ | async as CarouselOffers" id="t-offersCarousel" class="carousel carousel-dark slide carousel-fade rounded-4 overflow-hidden" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button *ngFor="let offer of CarouselOffers.slice(0, 3); let i = index" type="button" [attr.data-bs-target]="'#t-offersCarousel'" [attr.data-bs-slide-to]="i" [class.active]="i === 0" [attr.aria-current]="i === 0 ? 'true' : null" [attr.aria-label]="'Slide ' + (i + 1)">
              </button>
            </div>
            <div class="carousel-inner">
              <div *ngFor="let offer of CarouselOffers.slice(0, 3); let i = index">
                <div class="carousel-item" [ngClass]="{ 'active': i === 0 }" [attr.data-bs-slide-to]="i" data-bs-interval="4000">
                  <a href="" target="_blank">
                    <img src="{{ offer.banners[0].fileUrl }}" class="d-block w-100" alt="{{ offer.title }}">
                    <div class="carousel-caption d-none d-md-block">
                      <h3 class="h4">{{ offer.title }}</h3>
                      <p class="text-secondary">{{ offer.descriptionLine1 }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#t-offersCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#t-offersCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-4">
        <!-- Offers Grid > Bottom grid Offers -->
        <div class="col" *ngFor="let offer of (offer$ | async)?.slice(2, 6)">
          <a href="{{ offer.url }}" target="_blank" class="t-offer-card">
            <div mat-ripple class="t-offer-card_content overflow-hidden position-relative rounded-4 overflow-hidden mb-3 pt-3 offer-img">
              <img *ngIf="contentLoaded" ngSrc="{{ offer.imageUrl }}" alt="{{ offer.descriptionLine }}" height="350" width="295" fetchpriority="high" />
              <div class="t-offer-card_content_price position-absolute start-0 bottom-0 w-100 d-flex align-items-end justify-content-between text-white p-4 pt-5 lh-1">
                <div class="pt-5 pe-3">
                  <div class="fs-6 lh-sm fw-semibold text-uppercase" *ngIf="offer.offerTypeCustomText">
                    {{offer.offerTypeCustomText }}
                  </div>
                </div>
                <div class="d-block text-end">
                  <div class="fs-6 lh-1 text-decoration-line-through mb-2">USD {{ offer.priceOld }}</div>
                  <div class="d-flex align-items-center fs-3 lh-1 fw-semibold m-0">
                    <span class="fs-6 me-1 fw-bold">USD</span> {{ offer.priceNew }}
                  </div>
                </div>
              </div>
            </div>
            <div class="t-offer-card_description">
              <h3 class="h4 text-dark mb-1">{{ offer.title }}</h3>
              <div class="fs-6 text-secondary t-descript">
                {{ offer.descriptionLine }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Large Banners -->
<section title="Banners" class="container d-none d-lg-block px-4 py-sm-5 mb-5">
  <div class="row row-cols-1 row-cols-xl-2 g-5">
    <div class="col">
      <!-- Large Banners > /promociones -->
      <a mat-ripple href="https://www.toctocviajes.com/promociones/" target="_blank" class="t-static-banner-lg h-100 d-flex bg-white text-dark position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden align-items-end justify-content-between">
        <div class="p-5 pe-0 h-100 d-flex flex-column align-items-start">
          <h3 class="mb-2 lh-sm">
            Conocé nuestras promos para los mejores destinos
          </h3>
          <p class="fs-5 text-secondary mb-4">
            Tenemos las mejores ofertas para vos.
          </p>
          <button href="" mat-flat-button color="primary" class="fw-semibold mt-auto p-4">
            Ver promos
          </button>
        </div>
        <img class="static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-promos--lg.webp" alt="Conocé nuestras promos para los mejores destinos" />
      </a>
    </div>
    <div class="col">
      <!-- Large Banners > Betoctoc -->
      <a mat-ripple href="https://www.toctocviajes.com/betoctoc" target="_blank" class="t-static-banner-lg h-100 d-flex bg-white text-dark position-relative border border-secondary border-opacity-10 rounded-4 overflow-hidden align-items-end justify-content-between">
        <div class="p-5 pe-0 h-100 d-flex flex-column align-items-start">
          <h3 class="mb-2 lh-sm">
            ¡Enterate antes de las mejores ofertas!
          </h3>
          <p class="fs-5 text-secondary mb-4">
            Sumate a la mejor comunidad de viajes desde tu celular.
          </p>
          <button href="" mat-flat-button color="primary" class="fw-semibold mt-auto p-4">
            Quiero suscribirme
          </button>
        </div>
        <img class="static-bg" src="https://assets.toctocviajes.com/img/static-banners/img--banner-betoctoc--lg.webp" alt="Enterate antes de las mejores ofertas con Betoctoc" />
      </a>
    </div>
  </div>
</section>

<!-- Benefits -->
<section class="bg-white d-none d-sm-block py-sm-5">
  <div class="container px-4 py-5">
    <h2 class="mb-2">Conocé los beneficios de comprar en TocTocViajes</h2>
    <p class="fs-5 text-secondary mb-5">Conocé los beneficios de comprar en TocTocViajes</p>
    <!-- Benefit 1 -->
    <div class="row row-cols-sm-2 row-cols-lg-3 g-5">
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-01.svg" alt="Todas las aerolíneas y hoteles en un mismo lugar." width="63" height="63" />
        <h3 class="fs-5 my-3">Todas las aerolíneas y hoteles <br class="d-none d-md-block"> en un mismo lugar.</h3>
        <p class="fs-6 text-secondary">
          Compará entre todas las aerolíneas y cientos de miles de hoteles en todo el mundo para acceder a los precios
          más convenientes.
        </p>
      </div>
      <!-- Benefit 2 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-02.svg" alt="Resolvé todo en un mismo lugar" width="63" height="63" />
        <h3 class="fs-5 my-3">¡Resolvé todo en un mismo lugar!</h3>
        <p class="fs-6 text-secondary">
          El pasaje al destino que soñaste, el hotel ideal y la asistencia en viaje para que todo salga perfecto.
        </p>
      </div>
      <!-- Benefit 3 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-03.svg" alt="Pagar es más fácil y seguro" width="63" height="63" />
        <h3 class="fs-5 my-3">Pagar es más fácil y seguro.</h3>
        <p class="fs-6 text-secondary">
          Podés hacerlo con tarjeta de crédito, transferencia bancaria o en redes de cobranza.
        </p>
      </div>
      <!-- Benefit 4 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-04.svg" alt="Estamos para ayudarte" width="63" height="63" />
        <h3 class="fs-5 my-3">Y no lo olvides, ¡estamos para ayudarte!</h3>
        <p class="fs-6 text-secondary">
          Más de 50 profesionales ubicados en Uruguay para darte asistencia vía telefónica, por mail, o en nuestro local
          de Rivera y Soca de lunes a viernes de 9 a 19 y sábados de 9 a 13hs.
        </p>
      </div>
      <!-- Benefit 5 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-05.svg" alt="Pagá tu viaje hasta en 18 cuotas sin recargo" width="63" height="63" />
        <h3 class="fs-5 my-3">Pagá tu viaje hasta en 18 cuotas sin recargo con Tarjetas de crédito
          Santander.</h3>
        <p class="fs-6 text-secondary">
          Y olvidate de los cobros adicionales en tu tarjeta por compras internacionales.
        </p>
      </div>
      <!-- Benefit 6 -->
      <div class="col">
        <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/benefits-06.svg" alt="Más de 60 años de experiencia en viajes." width="63" height="63" />
        <h3 class="fs-5 my-3">Más de 60 años de experiencia en viajes.</h3>
        <p class="fs-6 text-secondary">
          Somos parte de 5M TravelGroup, un grupo con medio siglo en la industria de viajes y tecnología aplicada al
          turismo.
        </p>
      </div>
    </div>
  </div>
</section>

<!-- Pyment Methods -->
<section class="t-payment-methods-section bg-white border-top border-dark border-opacity-10">
  <div class="t-smile container px-4">
    <h2 class="mb-2">Viajar es mucho más fácil</h2>
    <p class="fs-5 text-secondary mb-5">
      Tenemos muchas formas de pago para que puedas hacer realidad tu viaje.
    </p>
    <div class="row justify-content-between g-5 g-xl-0">
      <div class="col-12 col-xl-auto">
        <h3 class="fs-5 fw-normal border-bottom border-secondary border-opacity-25 pb-3 mb-4">Tarjetas de crédito</h3>
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-visa.svg" alt="Visa" width="51" height="17" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-master-card.svg" alt="Master Card" width="44" height="27" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-oca.svg" alt="OCA" width="50" height="16" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-amex.svg" alt="AMEX" width="61" height="16" />
        <img class="mb-3" src="https://assets.toctocviajes.com/img/logos/cards/logo-prex.svg" alt="Prex" width="53" height="17" />
        <div class="d-flex mb-3">
          <img src="https://assets.toctocviajes.com/img/18-cuotas-santander.svg" width="297" height="29" alt="Hasta 18 cuotas sin recargo con Santander" />
        </div>
      </div>
      <div class="col-12 col-xl-auto">
        <h3 class="fs-5 fw-normal border-bottom border-secondary border-opacity-25 pb-3 mb-4">Transferencias</h3>
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-santander.svg" alt="Santander" width="102" height="18" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-brou.svg" alt="BROU" width="97" height="27" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-bbva.svg" alt="BBVA" width="64" height="20" />
        <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-scotiabank.svg" alt="Scotiabank" width="95" height="14" />
        <img class="mb-3" src="https://assets.toctocviajes.com/img/logos/banks/logo-itau--orange.svg" alt="Itaú" width="34" height="34" />
      </div>
      <div class="col-12 col-xl-auto">
        <h3 class="fs-5 fw-normal border-bottom border-secondary border-opacity-25 pb-3 mb-4">Redes de Cobranza</h3>
        <div class="d-flex">
          <img class="me-4 mb-3" src="https://assets.toctocviajes.com/img/logos/logo-abitab.svg" alt="Abitab" width="89" height="25" />
          <img class="mb-3" src="https://assets.toctocviajes.com/img/logos/logo-redpagos.svg" alt="Redpagos" width="97" height="19" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Testimonials -->
<section class="d-none d-sm-block py-sm-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-12">
        <h2 class="h2 mb-5">Miles de uruguayos confiaron en TocTocViajes y lo recomiendan</h2>
        <div class="row row-cols-sm-2 row-cols-xl-4 g-5">
          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fa-lg text-primary me-2"></i>
                <span class="fs-6 fw-bold text-dark">Mayo 2022</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4" cite='#'>
                Quería agradecerles por todo! Tuvimos un viaje hermoso!! Gracias por toda la ayuda y atención que le
                dieron a mí y a mi familia!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Liliam</span>
                <span class="fs-6 text-secondary">Viajó a Lima</span>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fa-lg text-primary me-2"></i>
                <span class="fs-6 fw-bold text-dark">Mayo 2022</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4" cite='#'>
                Quería agradecerles por todo! Tuvimos un viaje hermoso!! Gracias por toda la ayuda y atención que le
                dieron a mí y a mi familia!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Liliam</span>
                <span class="fs-6 text-secondary">Viajó a Lima</span>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fa-lg text-primary me-2"></i>
                <span class="fs-6 fw-bold text-dark">Mayo 2022</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4" cite='#'>
                Quería agradecerles por todo! Tuvimos un viaje hermoso!! Gracias por toda la ayuda y atención que le
                dieron a mí y a mi familia!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Liliam</span>
                <span class="fs-6 text-secondary">Viajó a Lima</span>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="t-testimonial-card d-flex flex-column h-100 bg-white shadow-sm rounded-4 p-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-message fa-lg text-primary me-2"></i>
                <span class="fs-6 fw-bold text-dark">Mayo 2022</span>
              </div>
              <blockquote class="fs-6 text-secondary my-4" cite='#'>
                Quería agradecerles por todo! Tuvimos un viaje hermoso!! Gracias por toda la ayuda y atención que le
                dieron a mí y a mi familia!
                Quería agradecerles por todo!
              </blockquote>
              <div class="mt-auto">
                <span class="d-block fs-6 fw-bold text-dark lh-sm mb-1">Liliam</span>
                <span class="fs-6 text-secondary">Viajó a Lima</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Links to landings -->
<section class="t-destinations-links-section py-sm-5">
  <div class="container px-4 py-5">
    <div class="row align-items-center">
      <div class="col-12 col-sm-11 col-xl-4 mb-5 mb-xl-0">
        <h2 class="h2 mb-4">Los destinos que más le gustan a nuestros viajeros</h2>
        <p class="t-description lead fw-normal text-secondary">
          En TocTocViajes encontrás vuelos, hoteles, vuelo + hotel, barco para
          ir a Buenos Aires, paquetes y asistencia de Assist Card en viaje. Podés comprar online o solicitar que uno
          de nuestros asesores te ayude a armar el viaje perfecto. Aprovechá nuestras ofertas y promociones para
          viajar a cualquier lugar del mundo.
        </p>
      </div>
      <div class="col-12 col-xl-8 ps-xl-5">
        <div class="row row-cols-1 row-cols-md-3 g-5">
          <!-- Popular destinations Flights -->
          <div class="col">
            <div class="bg-white p-3 p-sm-4 rounded-4 shadow-sm mb-3 m-lg-0">
              <div class="d-flex align-items-center mb-4">
                <i class="fa-kit fa-flights-product-regular fa-xl text-primary me-2"></i>
                <h5 class="m-0">Vuelos</h5>
              </div>
              <div class="row row-cols-2 row-cols-md-1">
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Rio-de-Janeiro-RIO/vuelos" target="_blank" title="Vuelos a Río de Janeiro">
                        <small>Vuelos a Río de Janeiro</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Salvador-de-Bahia-SSA/vuelos" target="_blank" title="Vuelos a Salvador de Bahía">
                        <small>Vuelos a Salvador de Bahía</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Miami-MIA/vuelos" target="_blank" title="Vuelos a Miami">
                        <small>Vuelos a Miami</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Nueva-York-NYC/vuelos" target="_blank" title="Vuelos a New York">
                        <small>Vuelos a New York</small>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Cancun-CUN/vuelos" target="_blank" title="Vuelos a Cancún">
                        <small>Vuelos a Cancún</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Punta-Cana-PUJ/vuelos" target="_blank" title="Vuelos a Punta Cana">
                        <small>Vuelos a Punta Cana</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Madrid-MAD/vuelos" target="_blank" title="Vuelos a Madrid">
                        <small>Vuelos a Madrid</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Barcelona-BCN/vuelos" target="_blank" title="Vuelos a Barcelona">
                        <small>Vuelos a Barcelona</small>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <!-- Popular destinations Hotels -->
          <div class="col pt-md-5">
            <div class="bg-white p-3 p-sm-4 rounded-4 shadow-sm mb-3 m-lg-0">
              <div class="d-flex align-items-center mb-4">
                <i class="fa-kit fa-hotels-product-regular fa-xl text-primary me-2"></i>
                <h5 class="m-0">Hoteles</h5>
              </div>
              <div class="row row-cols-2 row-cols-md-1">
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Rio-de-Janeiro-RIO/hoteles" target="_blank" title="Hoteles en Río de Janeiro">
                        <small>Hoteles en Río de Janeiro</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Salvador-de-Bahia-SSA/hoteles" target="_blank" title="Hoteles en Salvador de Bahía">
                        <small>Hoteles en Salvador de Bahía</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Miami-MIA/hoteles" target="_blank" title="Hoteles en Miami">
                        <small>Hoteles en Miami</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Nueva-York-NYC/hoteles" target="_blank" title="Hoteles en New York">
                        <small>Hoteles en New York</small>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Cancun-CUN/hoteles" target="_blank" title="Hoteles en Cancún">
                        <small>Hoteles en Cancún</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Punta-Cana-PUJ/hoteles" target="_blank" title="Hoteles en Punta Cana">
                        <small>Hoteles en Punta Cana</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Madrid-MAD/hoteles" target="_blank" title="Hoteles en Madrid">
                        <small>Hoteles en Madrid</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Barcelona-BCN/hoteles" target="_blank" title="Hoteles en Barcelona">
                        <small>Hoteles en Barcelona</small>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <!-- Popular destinations Packages -->
          <div class="col">
            <div class="bg-white p-3 p-sm-4 rounded-4 shadow-sm mb-3 m-lg-0">
              <div class="d-flex align-items-center mb-4">
                <i class="fa-kit fa-packages-product-regular fa-xl text-primary me-2"></i>
                <h5 class="m-0">Paquetes</h5>
              </div>
              <div class="row row-cols-2 row-cols-md-1">
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Rio-de-Janeiro-RIO/paquetes" target="_blank" title="Paquetes en Río de Janeiro">
                        <small>Paquetes en Río de Janeiro</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Brasil/Salvador-de-Bahia-SSA/paquetes" target="_blank" title="Paquetes en Salvador de Bahía">
                        <small>Paquetes en Salvador de Bahía</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Miami-MIA/paquetes" target="_blank" title="Paquetes en Miami">
                        <small>Paquetes en Miami</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/America-del-Norte/Nueva-York-NYC/paquetes" target="_blank" title="Paquetes en New York">
                        <small>Paquetes en New York</small>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col pe-0">
                  <ul class="lh-sm">
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Cancun-CUN/paquetes" target="_blank" title="Paquetes en Cancún">
                        <small>Paquetes en Cancún</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Caribe/Punta-Cana-PUJ/paquetes" target="_blank" title="Paquetes en Punta Cana">
                        <small>Paquetes en Punta Cana</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Madrid-MAD/paquetes" target="_blank" title="Paquetes en Madrid">
                        <small>Paquetes en Madrid</small>
                      </a>
                    </li>
                    <li>
                      <a class="t-link-underline d-block text-black mt-2 mt-sm-3" href="https://www.toctocviajes.com/viajar/Europa/Barcelona-BCN/paquetes" target="_blank" title="Paquetes en Barcelona">
                        <small>Paquetes en Barcelona</small>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--carousel ofertas-->
<!-- <div *ngIf="Carouseloffer$ | async as CarouselOffers">
  <ul>
    <li *ngFor="let offer of CarouselOffers">{{ offer.title }}</li>
  </ul>
</div> -->
<!-- Componentes y estilos -->
<!-- <div class="container pt-5">
  <div class="row">
    <div class="col-12">
      <h3>Basic Buttons</h3>
      <div class="mb-5">
        <button mat-button>Basic</button>
        <button mat-button color="primary">Primary</button>
        <button mat-button color="accent">Accent</button>
        <button mat-button color="warn">Warn</button>
        <button mat-button disabled>Disabled</button>
        <a mat-button routerLink=".">Link</a>
      </div>

      <h3>Raised Buttons</h3>
      <div class="mb-5">
        <button mat-raised-button>Basic</button>
        <button mat-raised-button color="primary">Primary</button>
        <button mat-raised-button color="accent">Accent</button>
        <button mat-raised-button color="warn">Warn</button>
        <button mat-raised-button disabled>Disabled</button>
        <a mat-raised-button routerLink=".">Link</a>
      </div>

      <h3>Stroked Buttons</h3>
      <div class="mb-5">
        <button mat-stroked-button>Basic</button>
        <button mat-stroked-button color="primary">Primary</button>
        <button mat-stroked-button color="accent">Accent</button>
        <button mat-stroked-button color="warn">Warn</button>
        <button mat-stroked-button disabled>Disabled</button>
        <a mat-stroked-button routerLink=".">Link</a>
      </div>

      <h3>Flat Buttons</h3>
      <div class="mb-5">
        <button mat-flat-button>Basic</button>
        <button mat-flat-button color="primary">Primary</button>
        <button mat-flat-button color="accent">Accent</button>
        <button mat-flat-button color="warn">Warn</button>
        <button mat-flat-button disabled>Disabled</button>
        <a mat-flat-button routerLink=".">Link</a>
      </div>

      <h3>Flat Buttons with Icon</h3>
      <div class="mb-5">
        <button mat-flat-button>
          <i class="fa-regular fa-heart fa-lg me-1"></i>Basic
        </button>
        <button mat-flat-button color="primary">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Primary
        </button>
        <button mat-flat-button color="accent">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Accent
        </button>
        <button mat-flat-button color="warn">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Warn
        </button>
        <button mat-flat-button disabled>
          <i class="fa-regular fa-heart fa-lg me-1"></i>Disabled
        </button>
        <a mat-flat-button routerLink="."><i class="fa-regular fa-heart fa-lg me-1"></i>Link</a>
      </div>

      <h3>Flat Buttons CTA</h3>
      <div class="mb-5">
        <button mat-flat-button class="p-4">Basic</button>
        <button mat-flat-button color="primary" class="p-4">Primary</button>
        <button mat-flat-button color="accent" class="p-4">Accent</button>
        <button mat-flat-button color="warn" class="p-4">Warn</button>
        <button mat-flat-button disabled class="p-4">Disabled</button>
        <a mat-flat-button routerLink="." class="p-4">Link</a>
      </div>

      <h3>Flat Buttons CTA Spinner</h3>
      <div class="mb-5">
        <button mat-flat-button class="p-4">
          <mat-spinner></mat-spinner>
        </button>
        <button mat-flat-button color="primary" class="p-4">
          <mat-spinner></mat-spinner>
        </button>
        <button mat-flat-button color="accent" class="p-4">
          <mat-spinner></mat-spinner>
        </button>
        <button mat-flat-button color="warn" class="p-4">
          <mat-spinner></mat-spinner>
        </button>
        <button mat-flat-button disabled class="p-4">
          <mat-spinner></mat-spinner>
        </button>
        <a mat-flat-button routerLink="." class="p-4">
          <mat-spinner></mat-spinner>
        </a>
      </div>

      <h3>Flat Buttons CTA with Icon</h3>
      <div class="mb-5">
        <button mat-flat-button class="p-4">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Basic
        </button>
        <button mat-flat-button color="primary" class="p-4">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Primary
        </button>
        <button mat-flat-button color="accent" class="p-4">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Accent
        </button>
        <button mat-flat-button color="warn" class="p-4">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Warn
        </button>
        <button mat-flat-button disabled class="p-4">
          <i class="fa-regular fa-heart fa-lg me-1"></i>Disabled
        </button>
        <a mat-flat-button routerLink="." class="p-4"><i class="fa-regular fa-heart fa-lg me-1"></i>Link</a>
      </div>

      <h3>Icon Buttons</h3>
      <div class="mb-5 d-flex">
        <button mat-icon-button matTooltip="Basic" aria-label="Example icon-button with a heart icon">
          <i class="fa-regular fa-heart"></i>
        </button>
        <button mat-icon-button matTooltip="Primary" color="primary" aria-label="Example icon-button with a heart icon">
          <i class="fa-regular fa-heart"></i>
        </button>
        <button mat-icon-button matTooltip="Accent" color="accent" aria-label="Example icon-button with a heart icon">
          <i class="fa-regular fa-heart"></i>
        </button>
        <button mat-icon-button matTooltip="Warn" color="warn" aria-label="Example icon-button with a heart icon">
          <i class="fa-regular fa-heart"></i>
        </button>
        <div matTooltip="Disabled" class="example-disabled">
          <button mat-icon-button disabled aria-label="Example icon-button with a heart icon">
            <i class="fa-regular fa-heart"></i>
          </button>
        </div>
        <a mat-icon-button matTooltip="Lik" routerLink="." aria-label="Example icon-button with a heart icon">
          <i class="fa-regular fa-heart"></i>
        </a>
      </div>

      <h3>Fab Buttons</h3>
      <div class="mb-5 d-flex">
        <button mat-fab matTooltip="Primary" color="primary" aria-label="Example fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </button>
        <button mat-fab matTooltip="Accent" color="accent" aria-label="Example fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </button>
        <button mat-fab matTooltip="Warn" color="warn" aria-label="Example fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </button>
        <div matTooltip="Disabled" class="example-disabled">
          <button mat-fab disabled aria-label="Example fab with a heart icon">
            <i class="fa-regular fa-heart fa-lg"></i>
          </button>
        </div>
        <a mat-fab matTooltip="Link" routerLink="." aria-label="Example fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </a>
      </div>

      <h3>Mini Fab Buttons</h3>
      <div class="mb-5 d-flex">
        <button mat-mini-fab matTooltip="Primary" color="primary" aria-label="Example mini fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </button>
        <button mat-mini-fab matTooltip="Accent" color="accent" aria-label="Example mini fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </button>
        <button mat-mini-fab matTooltip="Warn" color="warn" aria-label="Example mini fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </button>
        <div matTooltip="Disabled" class="example-disabled">
          <button mat-mini-fab disabled aria-label="Example mini fab with a heart icon">
            <i class="fa-regular fa-heart fa-lg"></i>
          </button>
        </div>
        <a mat-mini-fab matTooltip="Link" routerLink="." aria-label="Example mini fab with a heart icon">
          <i class="fa-regular fa-heart fa-lg"></i>
        </a>
      </div>

      <h3>Extended Fab</h3>
      <div class="mb-5">
        <button mat-fab extended color="primary">
          <i class="fa-regular fa-heart fa-lg me-1"></i>
          Primary
        </button>
        <button mat-fab extended color="accent">
          <i class="fa-regular fa-heart fa-lg me-1"></i>
          Accent
        </button>
        <button mat-fab extended color="warn">
          <i class="fa-regular fa-heart fa-lg me-1"></i>
          Warn
        </button>
        <button mat-fab extended disabled>
          <i class="fa-regular fa-heart fa-lg me-1"></i>
          Disabled
        </button>
        <a mat-fab extended routerLink=".">
          <i class="fa-regular fa-heart fa-lg me-1"></i>
          Link
        </a>
      </div>

      <h3>Inputs</h3>
      <div class="mb-5">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="t-mat-form-field">
              <mat-label>Clearable input</mat-label>
              <input matInput type="text" disabled="" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="t-mat-form-field">
              <mat-label>Clearable input</mat-label>
              <input matInput type="text" />
              <button matSuffix mat-icon-button aria-label="Clear">
                <i class="fa-regular fa-xmark fa-2xs"></i>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="t-mat-form-field">
              <i class="fa-regular fa-location-dot fa-lg text-primary me-2"></i>
              <input type="text" matInput placeholder="Origen" class="text-truncate" />
              <button matSuffix mat-icon-button aria-label="Clear">
                <i class="fa-regular fa-xmark fa-2xs"></i>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-form-field">
              <mat-label>Clearable input</mat-label>
              <input matInput type="text">
              <button matSuffix mat-icon-button aria-label="Clear">
                <i class="fa-regular fa-xmark fa-2xs"></i>
              </button>
            </mat-form-field>

            <mat-form-field appearance="fill" class="example-form-field">
              <mat-label>Clearable input</mat-label>
              <input matInput type="text">
              <button matSuffix mat-icon-button aria-label="Clear">
                <i class="fa-regular fa-xmark fa-2xs"></i>
              </button>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Contraseña</mat-label>
              <input type="password" matInput placeholder="Ingresa tu contraseña" [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <i class="fa-regular {{hide ? 'fa-eye-slash' : 'fa-eye'}} fa-2xs"></i>
              </button>
            </mat-form-field>

            <mat-form-field appearance="outline" class="t-mat-form-field">
              <mat-label>Telephone</mat-label>
              <span matPrefix>+598&nbsp;&#124;</span>
              <input type="tel" matInput placeholder="555-555-1234" />
              <i matSuffix class="fa-regular fa-phone me-2"></i>
            </mat-form-field>

            <button mat-raised-button matTooltip="Info about the action" matTooltipClass="example-tooltip-uppercase"
              aria-label="Button that shows a red tooltip" class="example-button">
              Uppercase-tooltip Action
            </button>

            <mat-form-field class="example-chip-list" appearance="fill">
              <mat-label>Favorite Fruits</mat-label>
              <mat-chip-grid #chipGrid aria-label="Enter fruits">
                <mat-chip-row *ngFor="let fruit of fruits" (removed)="remove(fruit)" [editable]="true"
                  (edited)="edit(fruit, $event)">
                  {{fruit.name}}
                  <button matChipRemove [attr.aria-label]="'remove ' + fruit.name">
                    <i class="fa-regular fa-xmark "></i>
                  </button>
                </mat-chip-row>
                <input placeholder="New fruit..." [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="add($event)" />
              </mat-chip-grid>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field class="t-mat-form-field">
              <mat-label>Favorite food</mat-label>
              <input matInput placeholder="Ex. Pizza" value="Sushi">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <mat-form-field class="t-mat-form-field">
              <mat-label>Leave a comment</mat-label>
              <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <h3>Basic mat-select</h3>
      <div class="mb-5">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="fill" class="t-mat-form-field">
              <mat-label>Select an option</mat-label>
              <mat-select disabled>
                <mat-option>None</mat-option>
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="fill" class="t-mat-form-field">
              <mat-label>Select an option</mat-label>
              <mat-select>
                <mat-option>None</mat-option>
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="t-mat-form-field">
              <mat-label>Select an option</mat-label>
              <mat-select>
                <mat-option>None</mat-option>
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
                <mat-option value="option3">Option 3</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <h3>Basic native select</h3>
      <div class="mb-5">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="fill" class="t-mat-form-field">
              <mat-label>Cars</mat-label>
              <select matNativeControl required disabled>
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="fill" class="t-mat-form-field">
              <mat-label>Cars</mat-label>
              <select matNativeControl required>
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="t-mat-form-field">
              <mat-label>Cars</mat-label>
              <select matNativeControl required>
                <option value="volvo">Volvo</option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->