import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { LoginComponent } from './pages/login/login/login.component';
import { PaymentTypesComponent } from './pages/static/payment-types/payment-types.component';
import { TravelInfoComponent } from './pages/static/travel-info/travel-info.component';
import { SiteMapComponent } from './pages/static/site-map/site-map.component';
import { FlightsHomeComponent } from './flights/containers/flights-home/flights-home.component';

const routes: Routes = [
  { path:'', pathMatch: 'full', component: FlightsHomeComponent },
  { path:'vuelos', pathMatch: 'full', component: FlightsHomeComponent },
  { path:'login', component: LoginComponent },
  { path:'promociones', component: PromotionsComponent},
  { path: 'formas-de-pago', component: PaymentTypesComponent },
  { path: 'info-viaje', component: TravelInfoComponent },
  { path: 'mapa-del-sitio/vuelos', component: SiteMapComponent },
  { path: 'mapa-del-sitio/hoteles', component: SiteMapComponent },
  { path: 'mapa-del-sitio/paquetes', component: SiteMapComponent },
  { path: 'mapa-del-sitio', redirectTo: 'mapa-del-sitio/vuelos', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
