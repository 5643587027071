import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxonFormat',
  standalone: true
})
export class LuxonFormatPipe implements PipeTransform {

  transform(
    value: Date,
    format: "shorter" | "short" | "long" | "shortWithHour" | "longWithHour" | "time" | null,
    customFormat?: string
  ): string {
    const formats = {
      shorter: "d MMM",
      short: "ccc d MMM",
      medium: "ccc d MMM yyyy",
      long: "cccc d 'de' MMMM yyyy",
      shortWithHour: "ccc, d MMM HH:mm",
      longWithHour: "cccc d 'de' MMMM yyyy HH:mm",
      time: "HH:mm"
    };

    if (!value) return '';
    const dateTime = DateTime.fromJSDate(value);
    
    if (format === 'short') {
      const dayName = dateTime.toFormat('ccc.');
      const dayNumber = dateTime.toFormat('d');
      let monthName = dateTime.toFormat('MMM'); 

      if (monthName.length > 3) {
        monthName = monthName.slice(0, 3);
      }

      return `${dayName} ${dayNumber} ${monthName}.`;
    }

    if (format) {
      return dateTime.toFormat(formats[format]);
    }
    if (customFormat) {
      return dateTime.toFormat(customFormat);
    }
    return '';
  }

}
