<div [matMenuTriggerFor]="roomsMenu" aria-label="Selección de huéspedes y habitaciones"
  class="d-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-rooms-button" mat-ripple>

  <i class="fa-light fa-user fa-lg text-primary me-3"></i>
  <span class="d-inline-flex fw-semibold me-4">2 Huéspedes</span>

  <i class="fa-light fa-bed-front fa-lg text-primary me-3"></i>
  <span class="d-inline-flex fw-semibold">1 Habitación</span>

</div>
<mat-menu class="t-rooms-menu rounded-3" #roomsMenu="matMenu" xPosition="before">
  <div class="px-3 pt-1" (click)="$event.stopPropagation()">
    <h4 class="mb-3 mat-headline-6 fw-normal">Habitaciones y huéspedes</h4>
    <!-- To +add room, repeat this element -->
    <div class="row">
      <div class="col-12">
        <mat-divider class="border-3 border-light mb-3"></mat-divider>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="mat-body-1 fw-semibold m-0">Habitación 1</h5>
          <a href="#" class="mat-small fw-semibold text-danger lh-sm py-2" aria-label="Eliminar Habitación">
            <i class="fa-regular fa-minus me-1"></i>Eliminar
          </a>
        </div>

        <!-- Adultos -->
        <div class="row align-items-center">
          <div class="col">
            <div class="mat-body-2 text-black lh-1 mb-1">Adultos</div>
          </div>
          <div class="col-5">
            <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3">
                <i class="fa-regular fa-minus"></i>
              </button>
              <div class="text-center">
                <span>5</span>
              </div>
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3">
                <i class="fa-regular fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- Menores -->
        <div class="row align-items-center my-3">
          <div class="col">
            <div class="mat-body-2 text-black lh-1 mb-1">Menores</div>
            <div class="mat-small text-black-50">De 0 a 17 años</div>
          </div>
          <div class="col-5">
            <div class="t-step-counter d-flex justify-content-between align-items-center overflow-hidden">
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3">
                <i class="fa-regular fa-minus"></i>
              </button>

              <div class="text-center">
                <span>5</span>
              </div>
              <button mat-stroked-button color="primary" tabindex="-1" class="rounded-3">
                <i class="fa-regular fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- <mat-divider class="my-3 border-light"></mat-divider> -->
        <div class="row">
          <div class="col-12">
            <div class="mat-body-2">Edad de los menores</div>
            <div class="mat-small text-black-50 mb-3">Ingresa las edades que tendrán al finalizar el viaje.
            </div>
          </div>
          <div class="col-12">
            <div class="row row-cols-2">
              <div class="col mb-3">
                <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages w-100">
                  <mat-label>Edad del niño 1</mat-label>
                  <select matNativeControl required>
                    <option value="" disabled selected></option>
                    <option value="1">1 año</option>
                    <option value="2">2 años</option>
                    <option value="3">3 años</option>
                    <option value="4">4 años</option>
                    <option value="5">5 años</option>
                    <option value="6">6 años</option>
                    <option value="7">7 años</option>
                    <option value="8">8 años</option>
                    <option value="9">9 años</option>
                    <option value="10">10 años</option>
                    <option value="11">11 año</option>
                    <option value="12">12 años</option>
                    <option value="13">13 años</option>
                    <option value="14">14 años</option>
                    <option value="15">15 años</option>
                    <option value="16">16 años</option>
                    <option value="17">17 años</option>
                  </select>
                </mat-form-field>
              </div>
              <div class="col mb-3">
                <mat-form-field appearance="outline" class="t-mat-form-field t-hide-bottom-messages w-100">
                  <mat-label>Edad del niño 2</mat-label>
                  <select matNativeControl required>
                    <option value="" disabled selected></option>
                    <option value="1">1 año</option>
                    <option value="2">2 años</option>
                    <option value="3">3 años</option>
                    <option value="4">4 años</option>
                    <option value="5">5 años</option>
                    <option value="6">6 años</option>
                    <option value="7">7 años</option>
                    <option value="8">8 años</option>
                    <option value="9">9 años</option>
                    <option value="10">10 años</option>
                    <option value="11">11 año</option>
                    <option value="12">12 años</option>
                    <option value="13">13 años</option>
                    <option value="14">14 años</option>
                    <option value="15">15 años</option>
                    <option value="16">16 años</option>
                    <option value="17">17 años</option>
                  </select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-between border-top border-opacity-25 py-3">
      <div class="col-auto">
        <button mat-button color="primary" class="w-100">
          <i class="fa-solid fa-plus me-1"></i>
          Agregar habitación
        </button>
      </div>
      <div class="col">
        <button mat-flat-button color="primary" class="w-100">
          Aplicar
        </button>
      </div>
    </div>
  </div>
</mat-menu>