import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component } from '@angular/core';
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import { OfferService } from './pages/promotions/components/offer/services/offer.service';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [OfferService]
})

export class AppComponent {
  title = 'ng-promociones';

  constructor() {}
}

