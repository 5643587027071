<div class="row pt-3 pb-2 px-2" *ngIf="modalTitle">
    <div class="col"><h5>{{modalTitle}}</h5></div>
    <div class="col-auto g-close-button" (click)="disMissBottomSheet()">
      <i class="fa fa-times"></i>
    </div>
  </div>
  <div class="col-12 px-3 py-4 g-date-picker-header" *ngIf="!disableRange">
    <div class="row">
      <div class="col text-center" (click)="selectDepartureDate()">
        <div [ngClass]="{'selected-date': selectingDeparture }">
          {{ getDepartureDate() | luxonFormat: "long" }}
        </div>
      </div>
    </div>
  </div>
  <div class="g-bottom-sheet-container">
    <div #mobilePicker>
      <ngb-datepicker
        (dateSelect)="onDateSelection($any($event))"
        [displayMonths]="monthsToDisplay"
        [dayTemplate]="single"
        navigation="none"
        outsideDays="hidden"
        [minDate]="_minDate"
        [maxDate]="_maxDate"
        [startDate]="startDate"
        class="custom-datepicker"
        [weekdays]="translationWidth"
        [markDisabled]="isDisabled"
      >
      </ngb-datepicker>
    </div>
  </div>
  
  <div class="g-material-bottom-sheet-footer" *ngIf="!disableRange">
    <button
      color="primary"
      class="g-stack-button px-5 py-2 mat-elevation-z10"
      mat-flat-button
      (click)="applyDates()"
      i18n="@@apply"
    >
      Aplicar
    </button>
  </div>

  <ng-template #single let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isSelected(date)"
    >
      {{ date.day }}
    </span>
  </ng-template>