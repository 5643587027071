  <form class="" (click)="showMobileForm($event)">
    <div class="row">
      <div class="col g-form-display">
        <mat-form-field appearance="outline" class="w-100 t-mat-form-field t-hide-bottom-messages">          
          <div class="d-flex align-items-center">            
            <ng-container *ngIf="spinning(); else pinMarker">
              <i class="fa fa-circle-notch fa-spin text-primary me-3"></i>
            </ng-container>
            <ng-template #pinMarker>
              <i class="fa-regular fa-location-crosshairs fa-lg text-primary me-3"></i>
            </ng-template>            
            <input #inputElement type="text" matInput [formControl]="formInput" [matAutocomplete]="auto"
                   placeholder="{{ placeHolder }}" class="fs-6 fw-semibold text-truncate" (blur)="selectFirstOption()" (focus)="onFocus($event)" />                   
            <ng-container *ngIf="formInput.value">
              <i class="fal fa-times ms-2" style="cursor: pointer;" (click)="formInput.setValue(undefined)"></i>
            </ng-container>
          </div>          
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplay" [panelWidth]="!isMobile ? '30rem' : 0"
                            [class]="'g-airport-search-autocomplete mat-elevation-z20'" (optionSelected)="onOptionSelected()">
            <ng-container *ngFor="let group of groupedAirports">
              <mat-option [value]="group.cityAirport" class="g-airport-search-autocomplete-city-name">
                <div class="row">
                  <div class="col">
                    {{ getCityName(group.cityAirport) }}
                    <div class="row">
                      <div class="col">
                        <span class="g-airport-search-close-location">{{group.cityAirport.closeRegionId}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-option>
              <mat-option *ngFor="let option of group.airports" [value]="option" class="g-airport-search-autocomplete-airport-name">
                <div class="row">
                  <div class="col-auto pr-0">
                    <i class="fas fa-level-up-alt fa-rotate-90"></i>
                  </div>
                  <div class="col">
                    <span class="g-airport-search-detail-code">{{ getCode(option) }}</span>
                    <span class="g-airport-search-detail-name">Aeropuerto {{ getDisplay(option) }}</span>
                    <div class="row">
                      <div class="col">
                        <span class="g-airport-search-close-location">{{option.closeRegionId}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-option>         
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>        
      </div>
    </div>
  </form>