<div class="d-none d-lg-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-date-picker-button"
    mat-ripple>
    <i class="fa-regular fa-calendar fa-lg text-primary me-3"></i>
    <div class="d-flex fs-6 fw-semibold">
        <button class="date-button me-2" [ngbPopover]="datePickerPopover" triggers="manual" #popOverFrom="ngbPopover"
            [placement]="displayMode === 'inline'? 'bottom' : 'right'" container="body" (click)="togglePopover('from')"
            [autoClose]="'outside'" [popoverClass]="'custom-popover'">
            {{ getDate(fromDate) | luxonFormat: "short" }}
        </button>
        <span class="me-2">-</span>
        <button class="date-button" [ngbPopover]="datePickerPopover" triggers="manual" #popOverTo="ngbPopover"
            [placement]="displayMode === 'inline'? 'bottom' : 'right'" container="body" [autoClose]="'outside'"
            (click)="togglePopover('to')" [popoverClass]="'custom-popover'">
            {{ getArrivalDate() }}
        </button>
        <span class="text-secondary ms-2 d-lg-none d-xxl-inline-flex"> - {{ getDaysDiff() }} días</span>
    </div>
</div>

<div class="d-lg-none d-flex align-items-center border border-1 border-opacity-10 rounded-3 px-3 t-date-picker-button"
    mat-ripple>
    <i class="fa-regular fa-calendar fa-lg text-primary me-3"></i>
    <div class="d-flex fs-6 fw-semibold">
        <button class="date-button me-2" (click)="showMobileDatepicker()">
            {{ getDate(fromDate) | luxonFormat: "short" }}
        </button>
        <span class="me-2">-</span>
        <button class="date-button" (click)="showMobileDatepicker()">
            {{ getDate(toDate) | luxonFormat: "short" }}
        </button>
        <span class="text-secondary ms-2 d-lg-none d-xxl-inline-flex"> - {{ getDaysDiff() }} días</span>
    </div>
</div>

<ng-template #datePickerPopover let-popover="popover">
    <div class="row">
        <div class="popover-datepicker-container" (click)="$event.stopPropagation()">
            <ngb-datepicker 
                (dateSelect)="onDateSelection($event)" 
                [displayMonths]="2" 
                [dayTemplate]="range"
                navigation="arrows" 
                outsideDays="visible" 
                [minDate]="_minDate" 
                [maxDate]="_maxDate"
                [startDate]="startDate" 
                (mouseleave)="onMouseLeave()" 
                triggers="manual" 
                class="custom-datepicker"
                [weekdays]="translationWidth"
            ></ngb-datepicker>
        </div>
    </div>
    <div class="row">
        <div class="col-auto">
            <span 
                class="t-flights-searcher-tabs"
                [ngClass]="{'t-disabled-link': !isSelectingFromDate(), 't-active-link': isSelectingFromDate()}"
            >
                IDA - {{ getDate(fromDate) | luxonFormat: "short" }}
            </span>
        </div>
        <div class="col-auto">
            <span 
                class="t-flights-searcher-tabs" 
                [ngClass]="{'t-disabled-link': isSelectingFromDate(), 't-active-link': !isSelectingFromDate()}"
            >            
                VUELTA - {{ getArrivalDate() }}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #range let-date let-focused="focused" let-currentMonth="currentMonth">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date">
        {{ date.day }}
    </span>
</ng-template>