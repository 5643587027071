<div class="col-12 g-bottom-sheet-header">
    <div class="row align-items-center py-3">
      <div class="col">
        <h5 class="m-0">{{ title }}</h5>
      </div>
      <div class="col-auto" (click)="close($event)">
        <i class="fal fa-2x text-secondary fa-times"></i>
      </div>
    </div>
  </div>
  <div class="g-bottom-sheet-container">
    <div class="col-12 mt-3 g-form-display">
      <div class="row">
        <div class="col">
          <form class="example-form">
            <mat-form-field
              class="g-autocomplete-field g-general-trucante g-mat-form-field-infix-border g-round-input"
              appearance="outline"
            >
              <div class="row py-2 ml-1">
                <div class="col-auto pl-0 pr-3 g-autocomplete-header-icon">
                  <ng-container *ngIf="spinning(); else pinMarker">
                    <i class="fa fa-circle-notch fa-spin"></i>
                  </ng-container>
                  <ng-template #pinMarker>
                    <i class="fal fa-map-marker-alt"></i>
                  </ng-template>
                </div>
                <div class="col pl-0 g-autocomplete-text mt-1">
                  <input
                    type="text"
                    matInput
                    #inputElement
                    [formControl]="formInput"
                    [placeholder]="getTitle()"
                  />
                </div>
              </div>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="row h-100">
        <div class="col">
          <ng-container *ngFor="let group of (airports | async)">
            <div
              class="row mx-2 mt-4 mb-3 g-autocomplete-options d-flex align-items-center"
              (click)="selectItem(group.cityAirport)"
            >
              <div class="col">
                {{ getCityName(group.cityAirport) }}
                <div class="row">
                  <div class="col">
                    <span class="g-airport-search-close-location mb-0">
                      {{group.cityAirport.closeRegionId}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let option of group.airports">
              <div class="row ml-3 mr-1 mb-3 g-autocomplete-options" (click)="selectItem(option)">
                <div class="col-auto pr-0 g-autocomplete-icon">
                  <i class="fas fa-level-up-alt fa-rotate-90"></i>
                </div>
                <div class="col" style="font-size: 0.9rem">
                  <span class="g-airport-search-detail-code">{{ getCode(option) }}</span>
                  <span class="g-airport-search-detail-name">
                    Aeropuerto {{ getDisplay(option) }}</span
                  >
                  <div class="row">
                    <div class="col">
                      <span class="g-airport-search-close-location mb-0">
                        {{option.closeRegionId}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>