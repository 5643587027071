<header *ngIf="router.url != '/login'">

  <!-- Header Slider ngIf: layout.showPromo -->
  <div id="t-header-carousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="3000">
        <a href="https://www.toctocviajes.com/promociones" target="_blank">
          <img class="d-md-none" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--mobile-1--v4.svg" style="object-fit:cover;height:45px;max-width:100%;" alt="Promo mobile" />
          <img class="d-none d-md-block" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--desktop-1--v4.svg" style="object-fit:cover;height:55px;max-width:100%;" alt="Promo Desktop" />
        </a>
      </div>
      <div class="carousel-item" data-bs-interval="3000">
        <a href="https://www.toctocviajes.com/promociones" target="_blank">
          <img class="d-md-none" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--mobile-2--v4.svg" style="object-fit:cover;height:45px;max-width:100%;" alt="Promo mobile" />
          <img class="d-none d-md-block" src="https://assets.toctocviajes.com/img/header-slider-msj/t-header-slider__item--desktop-2--v4.svg" style="object-fit:cover;height:55px;max-width:100%;" alt="Promo desktop" />
        </a>
      </div>
    </div>
  </div>
  <!-- END - Header Slider BE -->

  <div class="bg-white">
    <!-- Sup messages -->
    <!-- Mensajes que se muestras encima del Header -->
    <div class="d-md-none py-2">
      <div class="container-sm">
        <a mat-ripple class="d-flex align-items-center justify-content-between bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill text-success px-3 py-1">
          <div class="d-flex align-items-center">
            <i class="fa-regular fa-store fa-sm"></i>
            <a class="text-success" href="https://wwwtest2.toctocviajes.com/sucursales" target="_self">
              <small class="ms-2">Conocé nuestras sucursales</small>
            </a>
          </div>
          <i class="fa-regular fa-chevron-right fa-sm"></i>
        </a>
      </div>
    </div>

    <!-- Header Base -->
    <div class="container-xxl px-sm-4 px-xxl-0 py-3">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <a href="/">
            <img src="https://assets.toctocviajes.com/img/logos/ttv/logo-ttv.svg" alt="Logo TocTocViajes.com" width="110" height="40" />
          </a>
        </div>
        <div class="col-auto d-flex">
          <!-- Ocultar si hay promo nghide: layout.showPromo -->
          <a mat-stroked-button class="d-none d-md-flex bg-success bg-opacity-10 border border-success border-opacity-10 rounded-pill text-success px-3 mx-1">
            <i class="fa-regular fa-store"></i>
            <a class="text-success" href="https://wwwtest2.toctocviajes.com/sucursales" target="_self">
              <small class="ms-2">Conocé nuestras sucursales</small>
            </a>
          </a>
          <mat-divider class="border-secondary border-opacity-10 d-none d-md-flex mx-2" [vertical]="true"></mat-divider>
          <a href="tel://+59829085555" mat-stroked-button class="rounded-pill border-secondary border-opacity-25 px-3 mx-1">
            <i class="fa-regular fa-phone"></i>
            <span class="fs-6 fw-semibold ms-2 d-none d-xl-inline-flex">2908 55 55</span>
          </a>

          <!-- Newsletter - Dropdown Menu -->
          <div class="d-none d-md-block">
            <app-desktop-newsletter />
          </div>

          <!-- Sidebar mobile - button -->
          <div class="d-lg-none ms-1">
            <button mat-stroked-button class="rounded-pill" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-bars"></i>
                <!-- <img class="ms-2 me-n2 rounded-circle" src="https://assets.toctocviajes.com/img/user-google-login.jpg" width="24px" height="24px"
                  alt="Usuario" /> -->
              </div>
            </button>
          </div>

          <!-- Logged User - Dropdown Menu -->
          <!-- <div class="d-lg-block ms-1">
            <button mat-stroked-button class="rounded-pill pe-2" [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <span class="fw-semibold">Matias</span>
              <img class="ms-2 rounded-circle" src="https://assets.toctocviajes.com/img/user-google-login.jpg" width="24px" height="24px" alt="Matias" />
            </button>
            <mat-menu #menu="matMenu" xPosition="before" class="mt-2">
              <button mat-menu-item>
                <i class="fa-regular fa-user me-3"></i>
                <span>Mi perfil</span>
              </button>
              <button mat-menu-item>
                <i class="fa-regular fa-arrow-right-from-bracket me-2"></i>
                <span>Cerrar sesión</span>
              </button>
            </mat-menu>
          </div> -->

          <!-- Unlogged user - Dropdown Menu -->
          <!-- <div class="d-none d-lg-block ms-1">
            <button mat-stroked-button class="rounded-pill" [matMenuTriggerFor]="unloggedMenu"
              aria-label="Example icon-button with a menu">
              <i class="fa-regular fa-user"></i>
            </button>
            <mat-menu #unloggedMenu="matMenu" xPosition="before" class="mt-2">
              <div class="text-center p-3">
                <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-user-ok.svg" width="60px"
                  alt="Inicio de sesión" class="mb-3" />
                <h2 class="fs-5 fw-semibold d-inline-table">Iniciá sesión y descubrí beneficios</h2>
                <button mat-flat-button color="primary" class="w-100 mb-2">Iniciar sesión</button>
                <button mat-button color="primary" class="w-100">Crear una cuenta</button>
              </div>
            </mat-menu>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Products Navbar Desktop -->
    <!-- Este componente aparece únicamente en desktop  -->
    <div class="d-none d-lg-block">
      <div class="container-xxl px-sm-4 px-xxl-0 py-2">
        <div class="row">
          <div class="col-12">
            <nav>
              <ul class="p-0 m-0 d-inline-flex">
                <li>
                  <a href="https://wwwtest2.toctocviajes.com/flights" target="_self" mat-flat-button class="rounded-pill me-1">
                    <i class="fa-kit fa-flights-product-light fa-lg"></i>
                    <span class="fs-6 ms-2">Vuelos</span>
                  </a>
                </li>
                <li>
                  <a href="https://wwwtest2.toctocviajes.com/hotels" target="_self" mat-flat-button class="rounded-pill me-1">
                    <i class="fa-kit fa-hotels-product-light fa-lg"></i>
                    <span class="fs-6 ms-2">Hoteles</span>
                  </a>
                </li>
                <li>
                  <a href="https://wwwtest2.toctocviajes.com/custom-package" target="_self" mat-flat-button class="rounded-pill me-1">
                    <i class="fa-kit fa-dp-product-light fa-lg"></i>
                    <span class="fs-6 ms-2">Vuelo + Hotel</span>
                  </a>
                </li>
                <li>
                  <a href="https://paquetes.toctocviajes.com/" target="_blank" mat-flat-button class="rounded-pill me-1">
                    <i class="fa-kit fa-packages-product-light fa-lg"></i>
                    <span class="fs-6 ms-2">Paquetes</span>
                  </a>
                </li>
                <li>
                  <a href="https://wwwtest2.toctocviajes.com/insurances" target="_self" mat-flat-button class="rounded-pill me-1">
                    <i class="fa-kit fa-insurances-product-light fa-lg"></i>
                    <span class="fs-6 ms-2">Asistencia</span>
                  </a>
                </li>
                <mat-divider class="border-secondary border-opacity-10 d-none d-xl-block me-3" [vertical]="true"></mat-divider>
                <li>
                  <a mat-flat-button class="rounded-pill" routerLink="/promociones" [routerLinkActive]="['promociones-tab']">
                    <i class="fa-kit fa-promotions-product-light fa-lg"></i>
                    <span class="fs-6 ms-2">Promociones</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider class="border-secondary border-opacity-10"></mat-divider><!-- Ocultar mat-divider en home -->
</header>

<!-- Sidebar mobile -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header justify-content-end pb-0">
    <button mat-button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">
      <i class="fa-light fa-xmark fa-lg"></i>
    </button>
  </div>

  <div class="offcanvas-body">
    <!-- Logged user -->
    <!-- <div>
      <div class="d-flex align-items-center mb-4">
        <img class="rounded-circle" src="https://assets.toctocviajes.com/img/user-google-login.jpg" width="60px" alt="Usuario" />
        <div class="ps-3">
          <span class="fs-4 fw-semibold">Matías</span><br />
          <span class="fs-6 text-secondary">matias.gonzalez@netviax.com</span>
        </div>
      </div>
      <div class="row mx-n3">
        <ul class="t-sidenav-list w-100 px-0 m-0">
          <li>
            <a mat-ripple href="" class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
              <i class="fa-regular fa-user"></i>
              Mi Perfil
            </a>
          </li>
          <li>
            <a mat-ripple href="" class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
              <i class="fa-regular fa-arrow-right-from-bracket"></i>
              Cerrar sesión
            </a>
          </li>
        </ul>
      </div>
    </div> -->

    <!-- Unlogged user -->
    <!-- <div class="text-center mb-4">
      <img src="https://assets.toctocviajes.com/img/illustrations/outline-icons/outline-icon-user-ok.svg" width="70px" height="54px"
        alt="Inicio de sesión" class="mb-3" />
      <h2 class="d-inline-table fs-5 mb-4">Iniciá sesión y descubrí beneficios</h2>
      <button mat-flat-button color="primary" class="w-100 mb-2">Iniciar sesión</button>
      <button mat-button color="primary" class="w-100">Crear una cuenta</button>
    </div> -->

    <!-- Access list -->
    <div class="row mx-n3">
      <ul class="t-sidenav-list w-100 px-0 m-0">
        <mat-divider class="my-2"></mat-divider>
        <li>
          <a href="https://wwwtest2.toctocviajes.com/flights" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-flights-product-regular"></i>
            Vuelos
          </a>
        </li>
        <li>
          <a href="https://wwwtest2.toctocviajes.com/hotels" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-hotels-product-regular"></i>
            Hoteles
          </a>
        </li>
        <li>
          <a href="https://wwwtest2.toctocviajes.com/custom-package" target="_self" class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-dp-product-regular"></i>
            Vuelo + Hotel
          </a>
        </li>
        <li>
          <a href="https://wwwtest2.toctocviajes.com/boats" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-boats-product-regular"></i>
            Buenos Aires
          </a>
        </li>
        <li>
          <a href="https://paquetes.toctocviajes.com" target="_blank" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-packages-product-regular"></i>
            Paquetes
          </a>
        </li>
        <li>
          <a href="https://wwwtest2.toctocviajes.com/hotels" target="_self" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-insurances-product-regular"></i>
            Asistencia
          </a>
        </li>
        <li>
          <a routerLink="/promociones" mat-ripple class="t-sidenav-list_item d-flex align-items-center w-100 fs-6 text-dark py-2 px-3">
            <i class="fa-kit fa-promotions-product-regular"></i>
            Promociones
          </a>
        </li>
        <mat-divider class="my-2"></mat-divider>
      </ul>
    </div>

    <!-- Banner Call-Center -->
    <a mat-ripple href="tel://+59829085555" class="d-flex position-relative align-items-end justify-content-between border border-dark border-opacity-10 rounded-3 text-dark overflow-hidden my-3">
      <div class="p-3 pe-0">
        <h4 class="fs-6 lh-1 mb-2">Estamos para ayudarte</h4>
        <small class="d-block text-secondary mb-4">
          Lunes a Domingo de 9 a 20hs.
        </small>
        <div class="d-flex fw-semibold align-items-center">
          <i class="fa-regular fa-phone fa-lg me-2"></i>
          <span class="fs-4 fw-semibold lh-1 m-0">2908 55 55</span>
        </div>
      </div>

      <img class="position-absolute bottom-0 end-0" src="https://assets.toctocviajes.com/img/static-banners/img-banner-call-center-agent.webp" width="140" alt="Estamos para ayudarte" />
    </a>

    <!-- Social media -->
    <div class="d-flex gap-3 justify-content-center mt-4">
      <a href="https://www.instagram.com/toctocviajes/" target="_blank" mat-mini-fab aria-label="Seguinos en Instagram" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-instagram-white.svg" width="42" height="42" alt="Instagram" />
      </a>
      <a href="https://www.facebook.com/toctocviajes" target="_blank" mat-mini-fab aria-label="Seguinos en Facebook" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-facebook-white.svg" width="42" height="42" alt="Facebook" />
      </a>
      <a href="https://goo.gl/X7k0TJ" target="_blank" mat-mini-fab aria-label="Seguinos en LinkedIn" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-linkedin-white.svg" width="42" height="42" alt="Linkedin" />
      </a>
      <a href="https://twitter.com/toctocviajes" target="_blank" mat-mini-fab aria-label="Seguinos en Twitter" class="bg-white shadow-none">
        <img src="https://tocuyprodmedia.blob.core.windows.net/img/icons/icon-x-twitter-white.svg" width="42" height="42" alt="X" />
      </a>
    </div>
  </div>
</div>