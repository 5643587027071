<!-- This banner is displayed in multiple locations and resolutions -->
<a mat-ripple href="https://paquetes.toctocviajes.com/paquetes" target="_blank"
  class="t-static-banner d-flex bg-white position-relative border border-dark border-opacity-10 rounded-3 overflow-hidden text-dark">
  <div class="d-grid p-3 pe-0">
    <h5 class="fw-normal mb-2">
      Conocé nuestra sección<br />
      de paquetes
    </h5>
    <span class="d-flex align-items-center align-self-end fs-6 fw-bold lh-1 py-1">
      Ver paquetes
      <i class="fa-solid fa-chevron-right fa-sm ms-1 mt-1"></i>
    </span>
  </div>
  <img class="position-absolute bottom-0 end-0 static-bg"  src="https://assets.toctocviajes.com/img/static-banners/img-banner-packages-promotions.webp"
    alt="Conocé nuestra sección de paquetes" />
</a>