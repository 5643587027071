import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Product } from '../models/product.model';

@Injectable({
  providedIn: 'root'
})

export class OfferService {

  private offer$: Observable<any> | undefined;
  private mainCampaign$: Observable<any> | null | undefined;
  homeOffer$: any;

  constructor(private http: HttpClient) {}

  public ReadMainCampaign(): Observable<any> {
    if (!this.mainCampaign$) {
      const url = `https://apitest.toctocviajes.com/api/landings/configuration`;
      this.mainCampaign$ = this.http.get<Product[]>(url).pipe(
        shareReplay(1)
      );
    }
    return this.mainCampaign$;
  }

  public Read(): Observable<any> {
    if (!this.offer$) {

      const url = `https://tocuytestwebapi.azurewebsites.net/api/offers/offerswithregionsfiltered?init=0&productType=-1&quantity=110&region=-1`;
      this.offer$ = this.http.get<Product[]>(url).pipe(
        shareReplay(1)
      );
    }
    return this.offer$;
  }

  OnShowRegionChange = new EventEmitter<any>();

  getProducts(filters: { region: string, product: string } = { region: "", product: "" }): Observable<Product[]> {
    return this.Read().pipe(
      map(response => {
        const filteredProducts = response.offers.filter((product: {
          offerTypeText: string;
          regionNameFormatted: string; region: string; product: string; }) => {
          return (filters.region === "" ? true : filters.region === product.regionNameFormatted ) && (filters.product === "" ? true : filters.product === product.offerTypeText)
        });
        return filteredProducts;
      })
    );
  }



  ScrollTop() {
    window.scrollTo(0, 0)
  }

  public getHomeOffersCarousel(): Observable<any[]> {
    if (!this.homeOffer$) {
      const url = `https://apitest.toctocviajes.com/api/offers/homebanneroffers`;
      this.homeOffer$ = this.http.get<any[]>(url).pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
    return this.homeOffer$;
  }
}

