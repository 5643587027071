import { Component, OnDestroy, OnInit } from '@angular/core';
import { TravelType } from 'src/app/models/flights/flight-utilities';
import { FlightSearchLegVM, FlightSearchVM } from '../flight-search-vm';
import { SearchService } from 'src/app/services/search.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { FlightSearchLegModel } from 'src/app/models/search/flight-search-leg';

@Component({
  selector: 'app-flight-search-bar-home',
  templateUrl: './flight-search-bar-home.component.html',
  styleUrl: './flight-search-bar-home.component.scss'
})
export class FlightSearchBarHomeComponent implements OnInit, OnDestroy {
  flightSearch: FlightSearchVM = new FlightSearchVM();
  navigationSub: Subscription = new Subscription;
  travelType: TravelType = TravelType.RoundTrip;
  links = [
    {
      description: 'Ida y vuelta',
      travelType: TravelType.RoundTrip
    },
    {
      description: 'Sólo ida',
      travelType: TravelType.OneWay
    },
    {
      description: 'Multidestino',
      travelType: TravelType.MultipleFlights
    }
  ];
  activeLink = this.links[0].travelType;

  TravelType = TravelType;

  constructor(
    private router: Router,
    private searchService: SearchService
  ) {

  }

  ngOnInit(): void {
    this.setInitialValues();
    this.navigationSub = this.router.events.subscribe((e: any) => {

      if (e instanceof NavigationEnd) {
        this.setInitialValues();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.navigationSub) this.navigationSub.unsubscribe();
  }

  setInitialValues() {
    const search = this.searchService.retrieveLastFlightSearch();
    if (!search) {
      this.flightSearch.simpleLeg.origin.setValue({ code: 'MVD', name: 'Montevideo (MVD)' });
      this.flightSearch.simpleLeg.isOneWay = false;
      this.activeLink = TravelType.RoundTrip;
    } else {
      if (search.isMultipleLegSearch) {
        this.flightSearch.isMultipleLegSearch = true
        this.flightSearch.legs = this.getLegs(search.legs)
      } else {
        this.flightSearch.isMultipleLegSearch = false
        this.flightSearch.simpleLeg = this.getSimpleLeg(search.simpleLeg)
        this.flightSearch.simpleLeg.isOneWay = search.travelType == TravelType.OneWay
      }

      this.flightSearch.passengers = search.passengers
      this.flightSearch.type = search.travelType
      this.activeLink = search.travelType
    }
  }

  getSimpleLeg(simpleLeg: FlightSearchLegModel): FlightSearchLegVM {
    let leg: FlightSearchLegVM = new FlightSearchLegVM();

    if (simpleLeg) {
      leg.origin.setValue(simpleLeg.origin)
      leg.destination.setValue(simpleLeg.destination)
      const departDate = this.getDepartDate(simpleLeg.departDate)
      leg.departDate = departDate
      const arrivalDate = this.getArrivalDate(simpleLeg.arrivalDate, departDate)
      leg.arrivalDate = arrivalDate
      leg.isOneWay = simpleLeg.isOneWay
    }
    return leg
  }

  getDepartDate(date: Date): Date {
    if (date) {
      if (date >= new Date()) {
        return date
      }
    }
    return new Date()
  }

  getArrivalDate(date: Date, departDate: Date): Date {
    if (departDate) {
      if (date) {
        if (date > departDate) {
          return date
        }
        const arrivalDate = new Date(departDate)
        arrivalDate.setDate(arrivalDate.getDate() + 1)

        return arrivalDate
      }
    }
    const today = new Date();
    today.setDate(today.getDate() + 1);

    return today;
  }

  getLegs(legsModel: FlightSearchLegModel[]) {
    let legs: FlightSearchLegVM[] = []

    if (legsModel) {
      legsModel.forEach((leg) => {
        legs.push(this.getSimpleLeg(leg));
      });
    }

    return legs
  }

  onTravelTypeSelected(travelType: TravelType) {
    this.activeLink = travelType;
    this.flightSearch.type = travelType;
    this.flightSearch.isMultipleLegSearch = travelType === TravelType.MultipleFlights;
    this.flightSearch.simpleLeg.isOneWay = travelType === TravelType.OneWay;
  }
}
