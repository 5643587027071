import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StateTransferService {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  get(key: string): any {
    if (this.isBrowser) {
      return localStorage.getItem(key) || environment[key];
    } else {
      return environment[key];
    }
  }

  set(key: string, value: any): void {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }
}
